.table-header {
  margin: 0;
  padding: 10px 25px;
  font-weight: 400;
  background: $white;
  border: $default-border;
  border-radius: 8px;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.table-header + .dynamic-table-container {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.alerts-form {
  overflow: auto;
  height: 100%;
  background: $white;
  border: $default-border;
  border-radius: 8px;

  .zylo-form {
    input:disabled,
    .DateInput__display-text--disabled {
      color: $blue;
      font-style: italic;
    }
  }
}
