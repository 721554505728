.saved-filters-modal {
  height: 65vh;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.saved-filters-tables {
  min-width: 51vw;
  min-height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
    padding: 0 2rem;

    .app-logo-container {
      margin-right: 10px;
    }
  }

  .company-app-saved-filters-explanation {
    max-width: 56rem;
    margin: 20px 30px 0;
    width: 50rem;
  }

  .saved-filters__heading {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: $extra-medium-font-size;
    font-weight: 285;
    text-align: left;
  }

  .saved-filters__close-button {
    margin-top: -1rem;
    margin-right: -0.85rem;
  }

  .saved-filters__tabs {
    flex: 1;

    .topbar {
      margin: 0;
      padding: 0 2rem;
      border-bottom: none;
      background-color: transparent;

      .tabs-labels {
        margin: 0;
        justify-content: flex-start;

        .tab-label + .tab-label {
          margin-left: 2rem;
        }

        .tab-label {
          min-width: 0;
          padding: 0 0 0.8rem 0;
          background: transparent;
          box-shadow: none;
          color: $darkgray;
          font-size: $default-font-size;

          &:first-child,
          &:last-child {
            border-radius: 0;
          }

          &:focus {
            outline: none;
            color: $blue;
          }

          &.active {
            color: $navy;
            background: transparent;
            border: none;
            border-bottom: 2px solid $blue;
            box-shadow: none;
            cursor: pointer;
          }
        }
      }
    }

    .tab-pane {
      display: flex;
      flex-direction: column;
      flex: 1;

      .search-filter-container {
        margin: 0.3rem 2rem 1.55rem;
      }

      .dynamic-table-container {
        flex: 1;

        .dynamic-table-header {
          color: $black;
          font-weight: 400;
          border-top: $default-border;
        }

        .ReactVirtualized__Grid {
          background-color: transparent;
        }

        .dynamic-table-cell {
          font-size: $small-font-size;
          font-weight: 400;

          button.handle-click-cell {
            color: inherit;
            text-align: inherit;
            width: inherit;
            height: inherit;
          }
        }
      }
    }
  }

  .saved-filters__content {
    background-color: transparent;
  }
}

.saved-filters-display {
  flex-flow: row-reverse;
  justify-content: space-between;
  transition: all 0.5s;

  .saved-filters-controls-first-row {
    .filters-name-container {
      @include invisible;

      .zylo-icon {
        margin-right: 8px;
      }
    }

    .filter-controls {
      margin-left: auto;

      .button + .button {
        margin-left: 2rem;
      }
    }
  }

  &.show-saved-filter-name {
    flex-direction: column;

    .filters-name-container {
      @include inView;
    }
  }

  .filters-display {
    margin: 0 auto 8px 0;
  }
}
