.tile-grid {
  display: flex;
  flex-flow: row wrap;
  box-sizing: content-box;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  padding: 2px;
  &.scrollable {
    overflow-y: auto;
  }

  span.no-data {
    @include absCenter;
  }

  .tile {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 12px);
    position: relative;
    margin: 6px;
    padding: 0;
    box-sizing: content-box;
    @media (max-width: 1200px) {
      flex-grow: 1;
    }
    > * {
      border-radius: 8px;
      border: 1px solid $lightgray;
      padding: 16px;
      &.error {
        border-color: $red;
      }
    }
  }
}

.option-tile {
  cursor: pointer;
}

.option-tile + .option-tile {
  border-left: 1px solid $lightgray;
}

.option-tile__container {
  border-radius: 0.625rem;
  margin: 0 1.25rem;
  min-width: 15rem;
  padding: 1.25rem;
  transition: background-color 0.5s ease;

  &:focus {
    outline: none;
  }
}

.option-tile__header {
  font-size: $small-font-size;
  font-weight: 400;
  margin-bottom: 0;
}

.option-tile__example {
  font-size: $extra-small-font-size;
}
