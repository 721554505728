.slide-in-right-enter {
  transform: translateX(-100%);
  transition: 1s;
}

.slide-in-right-enter.slide-in-right-enter-active {
  transform: translateX(0);
}

.slide-in-right-leave {
  transform: translateX(100%);
  transition: 0.1s;
}

.slide-in-right-leave.slide-in-right-leave-active {
  transform: translateX(0);
}

.animatable {
  transition: all $default-transition;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    &.fade-in {
      opacity: 1;
      visibility: visible;
    }
  }
  &.fade-in {
    opacity: 1;
    visibility: visible;
  }
}

@-ms-keyframes slide-in {
  from {
    left: 215px;
    opacity: 0;
  }
  to {
    left: 770px;
    opacity: 1;
  }
}

@-moz-keyframes slide-in {
  from {
    left: 215px;
    opacity: 0;
  }
  to {
    left: 770px;
    opacity: 1;
  }
}

@-webkit-keyframes slide-in {
  from {
    left: 215px;
    opacity: 0;
  }
  to {
    left: 770px;
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    left: 215px;
    opacity: 0;
  }
  100% {
    left: 770px;
    opacity: 1;
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes download-animate {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(100%);
  }
  50% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
  }
  60% {
    visibility: visible;
    opacity: 1;
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
