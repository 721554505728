.topbar {
  display: flex;
  align-items: center;
  min-height: 78px;
  background: $backgroundgray;
  border-bottom: $default-border;

  .left-content,
  .center-content,
  .right-content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 100%;
  }

  .left-content {
    min-width: 280px;
    flex-basis: 30%;

    .page-metrics {
      .page-metric-number {
        margin: 0;
        font-size: $large-font-size;
        font-weight: 200;
      }

      .page-metric-description {
        font-size: $small-font-size;
        font-weight: 400;
      }
    }
  }

  .center-content {
    flex-basis: 20%;
  }

  .right-content {
    flex-basis: 30%;
    justify-content: flex-end;
  }
}
