// temporarily hide functionality opportunity button until fix for table filtering on same page can be found
.insight-opportunities.opportunities-list .functionality-opportunity {
  .redundant-apps-link {
    display: none !important;
  }
}
.insight-opportunities .functionality-opportunity {
  .dynamic-table-container {
    margin: 0 10px;
    max-height: 300px;
    min-height: 0;
  }
  .app-logo-container {
    width: 26px;
    min-width: 26px;
    height: auto;
    min-height: auto;
    border-radius: 50%;
    .app-logo {
      padding: 0;
    }
  }
  .dynamic-table-header.appLabel > .flex-container {
    padding-left: 0;
  }
  .appLabel {
    color: $bluegray;
  }
  .dynamic-table-container.compact .data-table .dynamic-table-cell .subscription-cell {
    padding-left: 0;
    padding-right: 0;
  }
  .redundant-apps-link {
    margin: 10px auto 0;
    padding: 10px;
  }
}
