.license-selector {
  display: inline-block;
  padding: 10px 20px;

  .select-container {
    margin: 0 10px;
    max-width: 300px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .Select.is-open > .Select-control {
      border-radius: 4px;
    }
    .Select-value-label {
      line-height: 37px;
    }
    .Select-menu-outer {
      margin: 2px 0 0 0;
    }
  }
  /* one item */
  .select-container:only-child {
    width: 100%;
  }
  /* two items */
  .select-container:first-child:nth-last-child(2),
  .select-container:first-child:nth-last-child(2) ~ .select-container {
    width: 50%;
  }
  /* three items */
  .select-container:first-child:nth-last-child(3),
  .select-container:first-child:nth-last-child(3) ~ .select-container {
    width: 33.3333%;
  }
  /* four items */
  .select-container:first-child:nth-last-child(4),
  .select-container:first-child:nth-last-child(4) ~ .select-container {
    width: 25%;
  }
}
