.breadcrumb-bar {
  &.hide {
    height: 0;
    visibility: hidden;
  }
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 45px;
  visibility: visible;
  position: relative;
  box-sizing: border-box;
  transition: height $default-transition;
  &.border-bottom {
    border-bottom: 1px solid $lightgray;
  }

  button.breadcrumb-back {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 12px;
    height: 100%;
    border-right: 1px solid $lightgray;
    border-radius: 0px;
    margin: auto;
  }
  .breadcrumb-title {
    flex-grow: 2;
    flex-shrink: 2;
    margin-left: 15px;
    & > * {
      font-size: $default-font-size;
    }
    span.breadcrumb-text {
      display: inline-block;
      height: 100%;
      &:not([disabled]):not(:last-child) {
        color: $blue;
      }
      &[disabled] {
        cursor: default;
      }
    }
    .breadcrumb-arrow {
      margin: -4px 5px auto 5px;
    }
  }
}

.crumb {
  height: calc(100% - 45px);
  position: relative;
}

.breadcrumb-bar.hide + .crumb {
  height: 100%;
}
