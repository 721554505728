$formelements: '.form-element, .Select, .type-ahead, .DateInput';

.line-items {
  display: block;
  width: 100%;
  padding-right: 65px;
  position: absolute;

  .add-line-item {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .delete-line-item {
    position: absolute;
    right: 25px;
    &:hover svg {
      stroke: $red !important;
    }
  }
  .row-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  div[role='row'] {
    width: 100%;
    display: flex;
  }
  .totals-row {
    background: $color-300;
    font-size: $small-font-size;
    max-height: 30px;

    .line-item-input {
      color: $dark;
      background: $color-300;
      padding: 0 23px;
      line-height: 30px;
      min-height: auto;

      &:not(:first-child) {
        color: $blue;
        font-weight: 400;
      }
    }
  }

  .line-item-input,
  .line-item-header {
    min-width: 150px;
    padding: 5px;
    flex-basis: 100%;
  }
  .line-item-input {
    min-height: 60px;
    &:last-of-type {
      text-align: right;
      input {
        text-align: right;
      }
    }
    input {
      width: 100%;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .validation-error {
      #{$formelements} {
        border-color: $red;
      }
    }
  }

  .line-item-header {
    font-weight: 400;
    font-size: 0.875rem;
    color: $darkgray;
    &:last-of-type {
      .header-text {
        text-align: right;
        padding: 0 17px 0 0;
      }
    }
  }

  .SingleDatePicker__picker {
    z-index: 3;
  }
}

// overrides for DefaultForm/ZFF
.zylo-form .zylo-form-field .line-items {
  width: 100%;
  max-width: 100%;
}
