.donut-container {
  position: relative;
  height: auto;

  svg {
    width: 100%;
    max-height: 250px;
    padding: 0;
    margin: -5px 0 0 -5px;
  }
  .donut-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    text-align: center;
    font-weight: 400;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 500ms;

    .donut-center-text {
      font-size: $small-font-size;
      color: $black;
    }

    .donut-center-percentage {
      font-size: 56px;
      line-height: 56px;
      font-weight: 200;
      color: $navy;
    }

    .donut-center-value {
      font-size: $small-font-size;
      color: $darkgray;
    }
  }

  &.transitioning .donut-center {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 500ms, opacity 500ms;
  }

  &.hidden {
    display: none;

    .donut-center {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 500ms, opacity 500ms;
    }
  }
}

.wave-chart-container {
  overflow: hidden;

  .loader-container {
    @include vcenter;
    position: absolute;
    left: 50%;
  }

  svg {
    overflow: visible;
  }

  .wave-chart-hover {
    padding: 0;
    pointer-events: none;
    @include inView;

    .hover-content {
      padding: 5px 15px;

      .hover-stat {
        padding: 0 15px;
        border-left: $default-border;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .hover-stat-label {
        font-size: $extra-small-font-size;
        line-height: 10px;
        color: $black;
      }
      &.vertical {
        padding: 5px;

        .hover-stat {
          padding: 5px 0 0 0;
          border-left: none;
          border-bottom: $default-border;
          font-size: $small-font-size;

          &.total {
            font-size: $default-font-size;
          }
          &:first-child {
            padding: 0;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.tetris-chart {
  display: block;
  padding: 1px;

  &.clickable {
    cursor: pointer;
  }

  &:hover g:not(:hover) {
    animation: fadeOut 0.3s linear forwards;
  }

  g {
    animation: fadeIn 0.3s ease-in forwards;

    rect {
      stroke: $white;
      opacity: 0;
      animation: fadeIn 0.3s ease-in forwards;
    }

    polyline {
      stroke: $white;
      stroke-width: 1;
      fill: transparent;
    }
  }

  .tetris-chart-active-section-outline {
    stroke: $pink;
    pointer-events: none;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    to {
      opacity: 0.25;
    }
  }
}

.category-chart-container {
  overflow: visible;
  font-size: $default-font-size;
  font-weight: 200;
  margin: auto;

  .category-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: -10px;

    .chart-legend {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 65%;
      margin-bottom: 10px;
    }
  }

  .loader-container {
    @include vcenter;
    position: absolute;
    left: 50%;
  }

  svg {
    overflow: visible;
  }

  svg path {
    cursor: pointer;
  }
}

.ribbon-chart-hover-box {
  pointer-events: none;
  @include inView;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: none;

  .card-legend {
    position: relative;
    top: 0;
    left: 0;
    font-size: $small-font-size;
    font-weight: 100;
    white-space: nowrap;

    .category-key {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.hover-box {
  position: absolute;
  @include invisible;
  background: $white;
  text-align: left;
  z-index: 9;
  padding: 5px 15px;
  border: $default-border;
  border-radius: 2px;
  box-shadow: 1px 2px 5px $lightgray;
}

.chart-hover-box {
  white-space: nowrap;
  transform: translateX(-50%);

  & > div,
  > span {
    display: inline-block;
  }
}

.chart-legend {
  display: flex;
  flex-direction: column;

  .legend-value {
    margin-bottom: 4px;
    font-size: $small-font-size;
    font-weight: 100;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
    .legend-color {
      display: inline-block;
      width: 0.55rem;
      height: 0.55rem;
      margin: 0 4px -2px;
      border-radius: 50%;
    }
  }
}

.bar-chart {
  .bar-chart__chart-container {
    position: relative;

    .bar-chart__grid {
      display: flex;
      align-items: stretch;
      height: 75%;
      width: 85.8%;
      position: absolute;
      top: 5%;
      left: 7.2%;
      z-index: 1;
      border-top: 0.3px solid $darkgray;
      pointer-events: none;
    }

    .bar-chart__grid-cell {
      border-right: 0.3px solid $darkgray;
      flex-grow: 1;
    }
  }
}

.bar-chart,
.category-chart-container {
  svg path {
    transition: fill-opacity 0.4s;
  }
}
