.dynamic-table-container .dynamic-table-cell .multi-value-cell {
  flex: 1;

  .multi-value-cell-sandbox {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: auto;
    visibility: hidden;
  }

  .main-values-container {
    flex: 1;
    overflow: hidden;
  }

  .show-more-tags {
    font-size: $extra-small-font-size;
    color: $blue;
    cursor: default !important;
  }

  &.read-only:hover {
    .tag {
      background: $blue;
      cursor: default !important;

      * {
        cursor: default !important;
      }
    }
  }
}

.ztable__cell {
  .multi-value-cell {
    flex: 1;

    .multi-value-cell-sandbox {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      overflow: auto;
      visibility: hidden;
    }

    .main-values-container {
      overflow: hidden;
    }

    .show-more-tags {
      font-size: $extra-small-font-size;
      color: $blue;
      cursor: default !important;
      margin-left: 0.5rem;
    }

    &.read-only:hover {
      .tag {
        background: $blue;
        cursor: default !important;

        * {
          cursor: default !important;
        }
      }
    }

    .tag {
      height: 30px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &--condensed {
    .multi-value-cell .tag {
      height: 18px;
    }
  }
}

.multi-value-cell-tooltip .tooltip-text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
