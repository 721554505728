.usage-opportunity {
  .usage-content {
    width: 100%;

    .usage-end-date {
      font-weight: bold;
      padding-left: 5px;
    }

    .usage-activity-threshold {
      font-weight: bold;
      padding-left: 5px;
    }
  }
}
