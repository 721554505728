#app {
  height: 100vh;
}

// zendesk support button
#launcher {
  height: 46px !important;
  background: $white;
  box-shadow: -2px 1px 10px $gray;
  border-radius: 23px;
}

.alert-type.renewal {
  background: #793e95;
} // purple

.trend-arrow {
  width: 0;
  height: 0;
  margin-right: 6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  &.upward {
    border-bottom: 6px solid $green;
  }
  &.downward {
    border-top: 6px solid $red;
  }
  &.none {
    border-left: none;
    border-right: none;
    height: auto;
    width: auto;
    &::after {
      content: '-';
      margin-top: -4px;
      color: $yellow;
      line-height: 36px;
      font-size: $large-font-size;
      display: block;
    }
  }
}

.full-height {
  height: 100%;
}

.vertical-bar {
  display: block;
  height: 60%;
  width: 2px;
  background-color: $lightgray;
  margin: auto 10px;
  border-radius: 2px 2px;
}

a {
  text-decoration: none !important;
}

// Moz input/buttons fixes
button,
a,
input {
  &:focus {
    outline: none !important;
  } // override bootstrap
  &::-moz-focus-inner {
    border: 0;
  }
  &:invalid {
    box-shadow: none;
  }
  &:-moz-submit-invalid {
    box-shadow: none;
  }
  &:-moz-ui-invalid {
    box-shadow: none;
  }
}

// color-specific classes
@each $color_name, $color_value in $colors {
  .color-#{'' + $color_name} {
    color: $color_value;
  }
  .icon-color-#{'' + $color_name} path {
    fill: $color_value;
  }

  .hover-color-#{'' + $color_name}:hover,
  .btn:hover .icon-hover-color-#{'' + $color_name},
  button:hover .icon-hover-color-#{'' + $color_name} {
    color: $color_value;
  }

  .icon-hover-color-#{'' + $color_name}:hover,
  .btn:hover .icon-hover-color-#{'' + $color_name},
  button:hover .icon-hover-color-#{'' + $color_name} {
    &.hover-fill {
      path,
      circle {
        fill: $color_value;
      }
    }
    &.hover-stroke {
      path,
      circle,
      rect {
        stroke: $color_value;
      }
    }
  }

  .fill-#{'' + $color_name} {
    fill: $color_value;
  }

  .background-#{'' + $color_name} {
    background-color: $color_value;
  }
  .background-hover-#{'' + $color_name}:hover {
    background-color: $color_value;
  }
}

.text-highlight {
  padding: 0 4px;
  color: $red;
  background: $backgroundgray;
  border: $default-border;
  border-radius: 4px;
}

.text-with-tooltip {
  white-space: nowrap;

  .zylo-tooltip {
    margin-left: 4px;
  }
}

// Cookie bar library styling
.wpcc-container {
  .wpcc-compliance {
    button {
      font-weight: 500;
      font-size: 14px;
      border: none;
    }
  }
}
