.details {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  padding: 0;
  background: $backgroundgray;
  z-index: 11;

  .loader-container {
    @include vcenter;
    position: absolute;
    left: 50%;
  }

  .delete-redirect-loader {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, 0.2);
  }

  .details-card {
    height: 100%;
    background: $white;
    border: $default-border;
    border-radius: 15px;
    overflow: hidden;
  }

  .details-content-container {
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 130px);
    padding: 20px 24px;
    background: $backgroundgray;
    overflow-y: auto;

    .details-content {
      height: 100%;
    }
    .details-tab-content {
      padding: 0;
      background: $white;
      overflow: hidden;

      .scrollable-content {
        height: 100%;
        border-radius: 0 0 15px 15px;
        overflow-x: hidden;
      }

      .dynamic-table-container {
        width: 100%;
      }
    }

    .details-card {
      background: $white;

      &.no-overflow {
        -webkit-mask-image: none;
        overflow: visible;
      }

      .dynamic-table-container {
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
        border-radius: 0;
      }

      &:first-child {
        width: 400px;
        height: 100%;
        max-height: 100%;
        margin: 0 25px 0 0;
        position: relative;
      }

      &:last-child {
        flex: 1 0 0;
        margin: 0;
      }
    }
  }

  .details-sidebar {
    position: relative;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .details-sidebar-header {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 50px;

      .supplier-link {
        position: relative;
        display: inline-block;
        left: 0;
        height: 24px;
        margin: 15px 0px 10px 0px;
        padding: 10px 20px;
        line-height: 0.4;
        font-weight: 200;
        color: $black;
        background: $lightgray;
        cursor: pointer;

        .supplier-name {
          color: $blue;
        }

        &:after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          border: 12px solid $lightgray;
          border-left-width: 1px;
          border-right-color: transparent;
          right: -12px;
        }
      }
    }

    .details-sidebar-content {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      position: relative;
      width: 100%;
      padding: 5px 30px 22px 30px;
      height: auto;
      margin-bottom: 1px;
      overflow-y: auto;

      .details-sidebar-top {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;

        .logo-status-container {
          position: relative;
        }
      }

      ul.flex-container {
        flex-flow: row nowrap;
      }

      .page-metrics {
        width: 100%;
        padding: 15px 0;
        border-top: 2px solid $lightgray;

        .metric:last-child .page-metric-number {
          color: $green;
        }

        .page-metric-number {
          font-size: $medium-font-size;
        }
        .page-metric-description {
          position: relative;

          > .zylo-tooltip {
            position: relative;
            top: inherit;
            right: inherit;
            margin: auto auto auto 5px;
          }
        }
      }
    }
  }
}

.office-365-active-users-tooltip {
  font-size: $default-font-size;

  .active-metric {
    margin: 8px 0;

    &:first-child {
      margin-top: 0;
    }

    .active-name {
      margin-right: 8px;
    }
  }
}
