@font-face {
  font-family: 'Quicksand', sans-serif;
}

.headline {
  font-size: 1.5rem;
  font-weight: 400;
  color: $navy;
}

.section-header {
  font-size: 1rem;
  font-weight: 500;
  color: $navy;
}

.body-black {
  font-size: 0.875rem;
  font-weight: 400;
  color: $black;
}

.body-gray {
  font-size: 0.875rem;
  font-weight: 400;
  color: $gray;
}

.body-navy {
  font-size: 0.875rem;
  font-weight: 400;
  color: $navy;
}

.body-white {
  font-size: 0.875rem;
  font-weight: 400;
  color: $white;
}

.body-gray-bold {
  font-size: 0.875rem;
  font-weight: 500;
  color: $gray;
}

.body-blue-bold {
  font-size: 0.875rem;
  font-weight: 500;
  color: $blue;
}

.body-white-bold {
  font-size: 0.875rem;
  font-weight: 500;
  color: $white;
}
