.nav-link {
  color: rgba(105, 105, 105, 0.6);
}

.navbar {
  padding: 0 0 0 36px;

  .navbar-brand {
    float: left;
    margin-right: 1rem;
    padding-top: 17px;
    padding-bottom: 17px;

    & img {
      max-height: 30px;
    }
  }

  .view-indicator {
    padding: 20px;
    color: $white;
    border-left: 1px solid $transparentgray;
  }
}
