.custom-field-form-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.custom-field-form {
  height: 100%;

  .header {
    flex: 0 0 30px;
    width: 100%;
    text-align: center;
  }
  .scrollable-content {
    overflow-x: hidden;
  }
  .cancel-and-confirm-buttons {
    flex: 0 0 50px;
    padding: 10px 0 0 0;
  }
}

.custom-field-config-background {
  width: 80%;
  max-height: 80vh;
  background: $backgroundgray;
}

.custom-field-config-form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .header {
    flex: 0 0 30px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }

  .subtitle {
    flex: 0 0 30px;
    font-weight: 400;
    text-align: center;
    width: 80%;
    margin: auto;
    padding-bottom: 20px;

    p {
      margin-bottom: 8px;
    }
  }

  .field-preferences-column {
    flex-basis: 33%;
    padding: 12px;
    transition: background $default-transition ease;
  }

  .column-headers {
    width: 100%;
    display: flex;

    .column-header {
      flex: 1 0 0;
      padding-top: 24px;

      &:last-child {
        border-right: none;
      }

      .zylo-form-fieldset-legend {
        display: block;
        width: 50%;
        margin: 0 24px;
        padding-bottom: 12px;
        font-weight: 400;
        white-space: nowrap;
        border-bottom: 1px solid $lightgray;
      }
    }
  }
  .form-scroll-container {
    flex: 1 0 0;
    overflow: auto;
    justify-content: center;

    .zylo-form {
      justify-content: space-between;
      flex-wrap: nowrap;

      .zylo-form-fieldset {
        border-right: none;

        .zylo-form-field {
          margin-top: 0px;
        }
      }
    }
  }
}

.custom-field-box {
  position: relative;
  height: 60px;
  padding: 16px;
  margin: 0 8px 12px 0;
  background-color: $white;
  border: 1px solid $lightgray;
  border-radius: 3px;

  .zylo-icon {
    margin: 3px 0 0 3px;
  }

  .custom-field-label {
    margin-bottom: 0px;
    padding: 0 12px;
    color: $darkgray;
  }

  .custom-field-description {
    padding-left: 6px;
    vertical-align: top;
  }

  .icon-info {
    margin: -3px 0 0 0;
  }
}

.custom-field-form-modal {
  max-height: 75vh;
}
