.image-carousel {
  position: relative;
  width: 100%;
  height: 100%;

  .image-carousel-arrow {
    position: absolute;

    &.image-carousel-back-arrow {
      left: 20px;
    }
    &.image-carousel-forward-arrow {
      right: 20px;
    }
  }

  .image-carousel-image,
  .image-carousel-description {
    max-width: 80%;
    max-height: 60%;

    &.image-carousel-image-full {
      max-width: 100%;
      max-height: unset;
    }
  }

  .image-carousel-external-link {
    margin-top: 12px;

    .icon-external-link svg {
      stroke: $blue;
    }
  }

  .image-carousel-description {
    margin: 24px 0;
    text-align: center;
  }

  .carousel-index-container {
    position: absolute;
    bottom: 5%;

    .carousel-index-dot {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background: $darkgray;
      border-radius: 4px;
      cursor: pointer;

      &.active {
        background: $blue;
        cursor: default;
      }
    }
  }
}
