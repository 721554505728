.flex-container {
  display: flex !important;

  &.vertical {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
  &.row-reversed {
    flex-direction: row-reverse;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-stretch {
    align-items: stretch;
  }
  &.align-self-start {
    align-self: flex-start;
  }
  &.align-self-end {
    align-self: flex-end;
  }
  &.wrap-normal {
    flex-wrap: wrap;
  }
  &.wrap-none {
    flex-wrap: nowrap;
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

div[class*='col-'] {
  position: relative;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.col-sm-12 {
  width: 100%;
}

@media (min-width: 810px) {
  .col {
    &-md {
      &-1 {
        flex-basis: 8.333333%;
      }
      &-2 {
        flex-basis: 16.666667%;
      }
      &-3 {
        flex-basis: 25%;
      }
      &-4 {
        flex-basis: 33.333333%;
      }
      &-5 {
        flex-basis: 41.666667%;
      }
      &-6 {
        flex-basis: 50%;
      }
      &-7 {
        flex-basis: 58.333333%;
      }
      &-8 {
        flex-basis: 66.666667%;
      }
      &-9 {
        flex-basis: 75%;
      }
      &-10 {
        flex-basis: 83.333333%;
      }
      &-11 {
        flex-basis: 91.666667%;
      }
      &-12 {
        flex-basis: 100%;
      }
    }
  }
}

@media (min-width: 1280px) {
  .col {
    &-lg {
      &-1 {
        flex-basis: 8.333333%;
      }
      &-2 {
        flex-basis: 16.666667%;
      }
      &-3 {
        flex-basis: 25%;
      }
      &-4 {
        flex-basis: 33.333333%;
      }
      &-5 {
        flex-basis: 41.666667%;
      }
      &-6 {
        flex-basis: 50%;
      }
      &-7 {
        flex-basis: 58.333333%;
      }
      &-8 {
        flex-basis: 66.666667%;
      }
      &-9 {
        flex-basis: 75%;
      }
      &-10 {
        flex-basis: 83.333333%;
      }
      &-11 {
        flex-basis: 91.666667%;
      }
      &-12 {
        flex-basis: 100%;
      }
    }
  }
}
