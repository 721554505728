.popover-menu-body {
  display: block;
  max-width: 300px;
  max-height: 400px;
  padding: 0;
  background: $white;
  white-space: nowrap;
  overflow: hidden;
  overflow-y: auto;

  .popover-menu-action {
    display: block;
    width: 100%;
    cursor: pointer;
    padding: 10px 16px;

    &:hover {
      background: lighten($lightgray, 5%);
    }

    &.disabled {
      cursor: not-allowed;
    }

    img {
      max-width: 25px;
    }

    .zylo-icon {
      margin-right: 10px;
    }
  }
}

.confirm-action {
  width: 250px;
  padding: 12px;

  .confirm-message {
    text-align: center;
    color: $navy;
    width: 100%;
  }

  .confirm-message-body {
    width: 100%;
    padding: 0 0 10px 0;
    color: $black;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .icon-confirm:hover svg {
    stroke: darken($green, 10%);
  }
  .icon-decline:hover svg {
    stroke: darken($red, 10%);
  }

  .confirm-anchor:hover {
    color: $white;
  }
}
