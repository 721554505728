.top-notification-bar {
  position: fixed;
  z-index: 12;
  top: 0px;
  left: 50%;
  width: auto;
  min-width: 300px;
  height: 0;
  padding: 0 20px;
  border-top: 40px solid $black;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-radius: 0 0 20px 20px;
  transition: 1s;
  transform: translateX(-50%);

  .top-notification-bar-content {
    display: block;
    margin-top: -20px;
    width: auto;
    color: $white;
    font-weight: 200;
    line-height: 0;
    white-space: nowrap;

    .zylo-icon {
      margin: -20px 8px 0 0;
      transform: translateY(8px);
    }
  }

  &.active,
  &:hover {
    top: 66px;
  }

  &.success {
    border-top-color: #007a79;
  }

  &.error {
    border-top-color: #c62828;
  }
}

.login-page .top-notification-bar {
  top: -40px;
  &.active,
  &:hover {
    top: 0px;
  }
}
