.int-service-row {
  .row-header {
    padding: 10px 20px;
    color: $black;
    font-weight: 400;
    background-color: $lightgray;
    border-bottom: $default-border;

    .app-logo-container {
      margin-right: 12px;
    }
  }

  .sidebar-metric {
    padding: 10px 30px;
    color: $black;
    font-size: $small-font-size;
    font-weight: 400;
    border-bottom: 1px solid $darkgray;
    position: relative;

    .sidebar-metric-value {
      color: $black;
      font-size: $default-font-size;
      padding-right: 10px;
    }

    .trend {
      display: inline-block;
      right: 5px;
      @include absvCenter;
    }
  }
}
