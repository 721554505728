.zylo-modal-mask {
  position: fixed;
  visibility: visible;
  opacity: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75%);
  transition: visibility 0.4s linear, opacity 0.4s;
  z-index: 11;

  &.old-modal {
    display: block;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;

    .zylo-modal {
      display: none;
    }
  }
}

.zylo-modal {
  &.old-modal {
    margin: 0 auto;
    max-height: 90vh;
    padding: 24px 20px;
    background: $white;
    border: $default-border;
    border-radius: 12px;
    box-shadow: 1px 2px 5px $lightgray;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  &.full-height {
    height: 85vh;
    margin: 66px auto 0 auto;
  }
  .modal-header {
    border-bottom: 1px solid $lightgray;
    padding: 0 0 12px 0;
    color: $navy;
    text-align: left;
    font-size: $medium-font-size;
    font-weight: 200;
  }
  .modal-contents {
    padding: 0;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 0 0;
    border: none;

    .modal-close {
      background: $blue;
    }

    .modal-action {
      margin-left: auto;
    }
  }
}

.user-list-alerts-modal {
  min-width: 60%;
  max-width: 100%;

  .table-header {
    margin: 0;
    padding: 0px 25px 10px 25px;
    font-weight: 400;
  }
}

@media (min-width: 1010px) {
  .user-list-alerts-modal .zylo-form .zylo-form-field {
    min-width: calc(50% - 36px);
    max-width: calc(50% - 36px);
  }
}

.close-modal {
  position: fixed;
  top: 75px;
  right: 50px;
  width: 50px;
  height: 50px;
  padding: 0 0 5px 0;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  line-height: 0.9;
  color: $white;
  border: 3px solid $white;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
  &:focus {
    opacity: 0.65;
  }
}

.mixpanel-notification-visible {
  #mixpanel-notification-cancel:hover {
    background: none;
  }

  &#mixpanel-notification-mini {
    #mixpanel-notification-mini-content,
    #mixpanel-notification-mini-icon {
      color: $black;
      background: $white;
    }

    #mixpanel-notification-mini-content {
      border-radius: 12px;
    }

    #mixpanel-notification-mini-icon-img {
      background-size: 48px 48px;
    }

    #mixpanel-notification-body {
      color: $black;
    }

    &:hover #mixpanel-notification-mini-content #mixpanel-notification-mini-icon {
      background-color: $white;
    }
  }

  &#mixpanel-notification-takeover {
    #mixpanel-notification-content {
      padding: 30px 20px;

      #mixpanel-notification-body {
        margin-bottom: 0;
      }
    }

    #mixpanel-notification-tagline,
    #mixpanel-notification-button-close {
      display: none;
    }

    #mixpanel-notification-button {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: normal;
      background: $white;
      cursor: default;

      #mixpanel-notification-button-link {
        padding: 10px 36px;
        font-weight: 200;
        color: $white;
        background: $yellow;
        border: 1px solid transparent;
        border-radius: 25px;

        &:hover {
          background: darken($yellow, 10%);
        }
      }
    }
  }
}

.confirmation-modal {
  padding: 30px;
  position: relative;

  svg.icon-close {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  h4.modal-header {
    font-size: $medium-font-size;
    color: $black;
    border: none;
  }

  .modal-contents > * {
    font-size: $small-font-size;
  }

  .modal-footer {
    padding-top: 10px;
    justify-content: space-around;
  }
}

.test-email-modal {
  .email-recipients-label {
    font-size: $default-font-size;
  }

  .type-ahead {
    width: 80%;
    min-height: 36px;
    margin: 16px 0;
  }
}
