// Chrome doesn't recognize mouseLeave events for disabled buttons. This is a workaround.
.zylo-tooltip {
  .button:disabled {
    pointer-events: none;
  }
}

.tooltip-body {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 16px;

  .tooltip-title {
    margin-bottom: 8px;
    color: $black;
    font-weight: 400;
    white-space: nowrap;
  }

  .tooltip-text {
    font-size: $small-font-size;
    font-weight: 100;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        white-space: nowrap;
      }
    }

    .total-spend .currency {
      margin-right: 4px;
    }
  }

  .tooltip-actions {
    margin-top: 12px;
  }

  &.tooltip__dark-mode {
    color: $white;
    background: $dark;
  }
}
