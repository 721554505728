.trends-list {
  float: left;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  list-style: none;

  .trends-list-header {
    margin: 0 0 5px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid $navy;

    .trends-title,
    .trends-sort {
      display: inline-block;
      margin: 0;
      width: 50%;
    }

    .trends-title {
      font-size: $medium-font-size;
      text-transform: uppercase;
      color: $navy;
    }

    .trends-sort {
      text-align: right;
      vertical-align: top;

      .btn {
        padding: 3px 5px;
        background: transparent;
        color: $navy;
        font-size: $extra-small-font-size;
        text-transform: uppercase;
        border: 1px solid $navy;
        border-radius: 5px;

        &:nth-child(1) {
          margin: 0 5px;
        }
        &.active {
          color: $white;
          background: $navy;
        }
      }
    }
  }

  p {
    color: $darkgray;
  }

  .trends-item {
    width: 100%;
    padding: 5px 0;
    margin: 5px 0;
    border-bottom: $default-border;

    &:nth-of-type(1) {
      margin: 0 0 5px 0;
    }
    &:nth-last-child(1) {
      border-bottom-width: 0;
    }

    img {
      display: inline-block;
      margin: 0 10px 0 0;
      max-width: 35px;
      max-height: 35px;
      padding: 4px;
    }

    .trend-item-info,
    .trend-item-data {
      font-size: $small-font-size;
    }

    .trend-item-info {
      width: 75%;
      height: auto;
    }

    .trend-item-data {
      width: 25%;
    }
  }

  .coming-soon {
    font-size: $medium-font-size;
    color: $darkgray;
    width: 100%;
    text-align: center;
    padding: 15px;
    margin: 5px 0;
    border: $default-border;
    text-transform: uppercase;
  }
}
