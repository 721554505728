.subscription-option {
  flex: 1;
  max-width: 100%;
  overflow: hidden;

  .app-logo-container {
    min-width: 32px;
  }

  .app-label {
    flex: 1;
    height: 24px;
    padding-left: 0.65rem;
    overflow: hidden;

    .highlight {
      color: $green;
    }
  }

  .zylo-tooltip {
    flex: 1;
    width: 18px;
    margin-left: 12px;
  }
}
