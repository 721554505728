.tag-list {
  width: 100%;
  padding: 10px 0;
  text-align: center;

  .inactive-tag {
    background: $darkgray;

    &:hover {
      background: lighten($darkgray, 10%);
    }
  }

  .tags-show-more {
    align-self: center;
    min-height: auto;
    max-height: 20px;
    font-size: $extra-small-font-size;
  }
}

.tag {
  display: flex;
  align-items: center;
  margin: 2px 5px 2px 0;
  padding: 4px 8px;
  white-space: nowrap;
  font-size: $extra-small-font-size;
  font-weight: 400;
  color: $white;
  background: $blue;
  border-radius: 6px;
  cursor: pointer;
  transition: $default-transition;

  &:hover {
    background: lighten($blue, 10%);
  }
  &.status-active {
    background: $green;
  }
  &.status-inactive {
    background: $orange;
  }
  &.status-cancelled {
    background: $red;
  }
  &.status-expired {
    background: $yellow;
  }
  &.not-clickable {
    cursor: default;

    &:hover {
      background: $blue;
    }
  }
}

.tag-list-popover-body {
  width: 400px !important;
  max-height: 250px;
  overflow-y: auto;
  padding: 5px 15px;
  white-space: unset;
}
