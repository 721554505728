.metric-container {
  .metric-label {
    @extend .body-gray;
    font-size: 0.875vw;
  }

  .metric-value {
    @extend .headline;
    margin-top: 8px;
  }

  .metric-button {
    @extend .body-blue-bold;
    font-size: 0.875vw;
  }
}
