input[type='button'] {
  -webkit-appearance: button;
}

[role='button'],
input[type='button'] {
  cursor: pointer;
}

button {
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: inherit;
  margin: 0;
  text-transform: none;

  &.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
  }

  &[disabled] {
    cursor: default;
  }
}

button,
.btn {
  transition: $default-transition;
  cursor: pointer;
}

.btn {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  border-radius: 25px;
  border: 1px solid transparent;
  display: inline-block;
  font-size: $default-font-size;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px 36px;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:active {
    background-image: none;
    outline: none;
    outline-offset: 0;
  }

  &:focus,
  &:focus:active,
  &.active:focus {
    outline: none;
    outline-offset: 0;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &[disabled],
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  span {
    vertical-align: middle;
  }
}

.btn-primary {
  background: $yellow;
  border: 0;
  color: $white;
  font-size: $small-font-size;
  font-weight: 100;

  &:hover {
    background: $yellow;
  }

  &:active {
    border: none;
    background: $yellow;
  }

  &:focus {
    outline: none;
    background: $yellow;
  }

  &:active:focus {
    background: $yellow;
  }

  &:active:hover {
    background: $yellow;
  }
}

.btn-wide {
  width: 100%;
  padding: 12px 50px;
}

.btn-orange {
  background: $orange;
  border: 0;
  color: $white;
  font-size: $small-font-size;

  &:hover {
    background: darken($orange, 10%);
  }
}

.btn-action,
.btn-action-danger {
  color: $white;
  padding: 6px 24px;
  border-radius: 25px;
  font-weight: 100;

  svg {
    stroke: $white;
  }

  &:focus {
    outline: 0;
  }
}

.btn-action {
  width: auto !important;
  background: $yellow;
  border: none;

  .icon-container {
    margin: 0 10px 0 0;

    &.right {
      margin: 0 0 0 10px;

      svg {
        margin-top: -5px;
      }
    }
  }

  &:hover {
    background: darken($yellow, 10%);
  }

  &.loading {
    background-color: $blue;

    .icon-container {
      .button-icon {
        display: inline-block;
        height: 18px;
        @include spin;
      }
    }
  }

  &.updating {
    background: $blue;
    border-color: $blue;

    &:hover {
      background: darken($blue, 5%);
      border-color: darken($blue, 5%);
    }
  }
}

.save-changes-btn {
  float: right;
  color: white;
  background-color: $yellow;
}

.btn-action-danger {
  border: 0;
  background: $red;

  &:hover {
    background: darken($red, 5%);
  }
}

.btn-action-cancel {
  border: 0;
  background: $darkgray;

  &:hover {
    background: darken($darkgray, 5%);
  }
}

button.close:focus {
  outline: none;
}

.btn-icon {
  padding: 3px;
  background: transparent;
  border: none;
}

.btn-link {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  color: $lightgray;
  font-weight: 400;
  &:hover {
    color: $darkgray;
    text-decoration: underline;
  }
}

.btn-link-blue {
  color: $blue;
  background: transparent;
  &:hover {
    color: $lightblue;
    background: transparent;
    text-decoration: underline;
    .button-icon svg {
      stroke: $lightblue;
    }
  }
  &[disabled],
  &:disabled {
    color: $lightgray;
    cursor: not-allowed;
    .button-icon svg {
      stroke: $lightgray;
    }
  }
  .button-icon svg {
    stroke: $blue;
  }
  .icon-container {
    margin: -3px 5px 0 0;
  }
}

.icon-btn {
  padding: 8px;
  border: none;
  background: transparent;
}

.download-btn {
  justify-content: space-between;
  span {
    padding-left: 10px;
  }
}

.button + .button,
.search-filter-container + .button {
  margin-left: 0.625rem;
}
