.primary-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 0;
  background: $backgroundgray;

  &.no-scroll {
    min-height: 100%;
    position: fixed;
  }
}
