.filter-list {
  min-width: 348px;
  max-width: 348px;
  height: 100%;
  overflow-y: auto;

  .filter-option {
    border-bottom: $default-border;

    .filter-header {
      padding: 10px 25px;
      background: $backgroundgray;

      .zylo-tooltip {
        margin-left: 10px;
      }
    }

    .switch-container .switch {
      .switch-inner:after {
        background: $darkgray;
      }
      .switch-inner:before {
        background: $blue;
      }
    }

    .filter-component {
      display: none;
      max-height: 0;
      box-sizing: border-box;
      padding: 0px 25px;
      transition: max-height 0.5s, border 0.5s, padding 0.2s 0.3s;
      overflow: hidden;

      .filter-date-picker {
        padding: 2px 0;

        .radio-list {
          margin-bottom: 12px;
        }
      }

      .Select--single .Select-input {
        padding-left: 18px;
      }
    }

    &.active .filter-component {
      display: block;
      padding: 10px 25px;
      max-height: 500px;
      overflow: visible;
      transition: max-height 0.5s, border 0.5s, padding 0.2s 0s;
    }

    .DateRangePicker {
      margin: 0;
    }
  }
}

.metadata-sidebar {
  max-width: 350px;
  min-width: 350px;
}
