@import '../mixins/mixins';

.error-landing-page {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  .center-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 70%;
    padding: 0 5px;
    max-width: 410px;
    .error-svg {
      margin: 40px auto;
      max-width: 75%;
      position: relative;
      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    p.subtext {
      margin-top: 5%;
    }
    button {
      margin: 10% auto;
    }
  }

  &.views-warning-page {
    width: auto;
    height: calc(100vh - 114px);
    margin: 24px;
    color: $darkgray;
    background: $white;
    border-radius: 10px;
    border: $default-border;
    overflow: hidden;

    img {
      max-width: 30%;
      margin-bottom: 30px;
    }
  }
}
