.search-filter-container {
  position: relative;
  display: inline-block;
  min-width: 260px;

  .search-action {
    cursor: pointer;
    @include absvCenter;
    right: 0.5rem;
    & + input[name='search']:disabled {
      cursor: not-allowed;
    }
  }

  input[name='search'] {
    padding: 5px 2rem 5px 8px;
    border: 1px solid $darkgray;
    border-radius: 5px;
    width: 100%;
    transition: all $default-transition;

    &:focus {
      border-color: #007070;
    }
    &:disabled {
      background: $lightgray;
      cursor: not-allowed;
    }
  }
}
