.rangeslider-horizontal {
  height: 20px;
  border-radius: 10px;

  .rangeslider__fill {
    height: 100%;
    background: $green;
    border-radius: 10px;
    top: 0;
  }

  .rangeslider__handle {
    width: 40px;
    height: 40px;
    top: -10px;
    border-radius: 40px;
  }
}
