.date-filter {
  padding: 8px;

  .date-picker-input {
    height: 36px;
  }

  .date-filter-custom-range,
  .date-filter-custom-range-days {
    margin: 8px 0 -8px;

    .select-container,
    .text-container {
      width: 89px;
    }

    .number-input-field {
      width: 70px;
      margin: 0 8px;

      input {
        max-width: 100%;
        min-height: 100%;
        border-color: $lightgray;
      }
    }
  }
  .date-filter-custom-range-days > div {
    margin-bottom: 8px;
  }

  .date-filter-inputs {
    padding: 8px 0 0;

    & > div {
      width: 128px;

      &:first-child {
        margin-right: 8px;
      }

      .SingleDatePickerInput {
        border-color: $lightgray;
        overflow: hidden;
      }

      .DateInput__display-text {
        font-size: $small-font-size;
      }
    }
  }
}

.filter-select {
  border-color: $lightgray;

  .Select-option-group {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $darkgray;

    .Select-option-group-label {
      display: none;
    }
  }

  .Select-option-group {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $darkgray;

    .Select-option-group-label {
      display: none;
    }
  }

  .Select-placeholder,
  .Select-option {
    font-size: $small-font-size;
  }
}
