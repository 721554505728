@import '../../styles/mixins/mixins.scss';

@mixin scrolledOff {
  @include invisible;
  max-height: 0;
  pointer-events: none;
}

@mixin scrolledIn {
  @include inView;
  max-height: 600px;
  pointer-events: auto;
}

.zylo-popover.filters-menu-popover {
  overflow: visible;

  .filters-menu-dropdown {
    position: relative;
    width: 280px;
    left: 0;
    border-radius: 5px;
    pointer-events: none;

    .filter-types-header {
      border-bottom: $default-border;
    }

    .filters-menu-title {
      margin: 8px 10px 0;
      font-weight: 400;
      pointer-events: auto;
    }

    &.filter-type-selected {
      left: -100%;

      .filters-menu-types {
        @include scrolledOff;
      }

      .filter-type-options {
        @include scrolledIn;
      }
    }

    .active-filters-indicator {
      margin-right: 12px;
      font-size: $extra-small-font-size;

      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        background: lighten($lightblue, 25%);
        border: 1px solid lightgray;
      }
    }

    .search-filter-container {
      margin: 8px 10px;
      font-size: $small-font-size;

      input[name='search'] {
        padding: 8px;
        border-color: $lightgray;
      }
    }

    .filter-type-list {
      max-height: 300px;
      overflow: auto;

      .filter-menu-group-title {
        width: 100%;
        margin: 0;
        padding: 4px 10px;
        background: $lightgray;
        font-size: $small-font-size;
        font-weight: 500;
        color: $black;
      }
    }

    .filters-menu-type {
      padding: 10px 8px;
      font-size: $small-font-size;
      background: $white;
      border-bottom: $default-border;
      cursor: pointer;

      .zylo-icon {
        pointer-events: none;
      }

      &.active {
        background: lighten($lightblue, 25%);
      }

      &.custom-date-range-filter-type {
        border-top: $default-border;
        border-bottom: none;
      }

      &:hover svg.zylo-icon {
        stroke: $blue;
      }
    }

    .filters-menu-types {
      background: $white;
      border-radius: 5px;
      pointer-events: auto;
    }

    .filter-type-options {
      background: $white;
      border-radius: 5px;
      @include scrolledOff;

      .filters-options-header {
        margin: 10px 10px 0;

        .back-to-menu {
          width: 96px;
          font-size: $extra-small-font-size;
          white-space: nowrap;
          color: $blue;
          overflow: hidden;
          cursor: pointer;
        }

        .filter-options-title {
          margin-top: 16px;
          font-weight: 400;
        }
      }

      .filter-options-main-content {
        background: $white;

        .date-picker {
          .date-picker-input {
            border-color: $lightgray;
          }

          &.date-picker__focused .date-picker-input {
            border-color: $blue;
          }
        }

        .type-ahead {
          margin-top: 6px;
          font-size: $small-font-size;
          border: none;
          border-top: $default-border;
          border-radius: 0;

          .pill-container {
            border-color: $lightgray;
          }

          .dropdown-container {
            .Select {
              margin-bottom: auto;
            }

            .Select-control {
              height: 42px;
              border-bottom: $default-border;
              border-radius: 0;

              .Select-placeholder {
                font-size: $small-font-size;
              }

              .Select-input {
                height: 40px;

                input {
                  height: 40px;
                  padding: 0 0 0 4px;
                }
              }
            }
          }

          .Select-menu-outer {
            position: relative;
            font-size: $small-font-size;
            border: none;
            border-radius: 0;
            box-shadow: none;
          }
        }

        .multi-select {
          border: 1px solid #cccccc;
          border-width: 1px 0;
          font-size: $small-font-size;
          max-height: 18rem;
          margin: calc(1.25rem / 2) 0;
          overflow: auto;
          padding: 4px 8px;
        }

        .radio-list {
          display: flex;
          align-items: center;
          padding: 8px;

          .radio-label {
            padding: 0;
          }

          & > .button {
            margin-left: auto;
          }
        }
      }

      .filter-options-footer {
        padding: 8px;
        background: $white;
        border-radius: 0 0 5px 5px;
      }

      .date-range-buckets-filter {
        position: relative;
        left: 0;
        border-radius: 5px;

        &.show-custom-date-range {
          left: -100%;
          pointer-events: none;

          .date-range-buckets-options {
            @include scrolledOff;
          }

          .custom-date-range-container {
            @include scrolledIn;
          }
        }

        & > * {
          min-width: 280px;
        }

        .custom-date-range-container {
          @include scrolledOff;

          .hide-custom-date-range {
            width: 150px;
            margin-top: 6px;
            padding: 10px;
            font-size: $extra-small-font-size;
            white-space: nowrap;
            color: $blue;
            overflow: hidden;
            cursor: pointer;
          }
        }
      }
    }

    & > * {
      min-width: 280px;
    }
  }
}
