.zylo-icon {
  display: inline-block;
  vertical-align: middle;
  @include no-select;

  path {
    transition: $default-transition;
  }

  &.spin {
    @include spin;
  }
  &.pointer {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.functionality-icon {
  margin-left: 5px;
}

.btn .zylo-icon {
  cursor: pointer !important;
}
.btn:disabled .zylo-icon {
  cursor: not-allowed !important;
}

.zylo-icon.icon-exclamation {
  transform: rotate(180deg);
}

.zylo-icon.icon-download.animate > path {
  &:nth-of-type(1),
  &:nth-of-type(2) {
    animation: download-animate 1.6s ease-out 1 both;
  }
}
