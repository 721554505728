.hr-divider {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  position: relative;
  min-height: 24px;
  margin: 0.5rem 0;

  .hr-div-line {
    flex-grow: 2;
    flex-shrink: 2;
    height: 1px;
    background-color: $darkgray;
  }

  &.light .hr-div-line {
    background-color: $color-300;
  }

  > *:not(.hr-div-line) {
    padding-left: 8px;
    padding-right: 8px;
  }
  > .center {
    @include absCenter;
  }
  > .left {
    order: -1;
  }
  > .right {
    order: 99;
  }
  .hr-button {
    background: none;
    border: none;
  }
}
