.oscillating-text-wrapper {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  overflow: hidden;

  .oscillating-text {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
  }
}

$text-overflow-values: (
  '10': (
    translation: translateX(-10px),
    animation: oscillating-text-10 infinite 3s ease-in-out,
  ),
  '20': (
    translation: translateX(-20px),
    animation: oscillating-text-20 infinite 3s ease-in-out,
  ),
  '30': (
    translation: translateX(-30px),
    animation: oscillating-text-30 infinite 3s ease-in-out,
  ),
  '40': (
    translation: translateX(-40px),
    animation: oscillating-text-40 infinite 3s ease-in-out,
  ),
  '50': (
    translation: translateX(-50px),
    animation: oscillating-text-50 infinite 4s ease-in-out,
  ),
  '60': (
    translation: translateX(-60px),
    animation: oscillating-text-60 infinite 4s ease-in-out,
  ),
  '70': (
    translation: translateX(-70px),
    animation: oscillating-text-70 infinite 4s ease-in-out,
  ),
  '80': (
    translation: translateX(-80px),
    animation: oscillating-text-80 infinite 4s ease-in-out,
  ),
  '90': (
    translation: translateX(-90px),
    animation: oscillating-text-90 infinite 5s ease-in-out,
  ),
  '100': (
    translation: translateX(-100px),
    animation: oscillating-text-100 infinite 5s ease-in-out,
  ),
  '110': (
    translation: translateX(-110px),
    animation: oscillating-text-110 infinite 5s ease-in-out,
  ),
  '120': (
    translation: translateX(-120px),
    animation: oscillating-text-120 infinite 5s ease-in-out,
  ),
  '130': (
    translation: translateX(-130px),
    animation: oscillating-text-130 infinite 6s ease-in-out,
  ),
  '140': (
    translation: translateX(-140px),
    animation: oscillating-text-140 infinite 6s ease-in-out,
  ),
  '150': (
    translation: translateX(-150px),
    animation: oscillating-text-150 infinite 6s ease-in-out,
  ),
  '160': (
    translation: translateX(-160px),
    animation: oscillating-text-160 infinite 6s ease-in-out,
  ),
  '170': (
    translation: translateX(-170px),
    animation: oscillating-text-170 infinite 7s ease-in-out,
  ),
  '180': (
    translation: translateX(-180px),
    animation: oscillating-text-180 infinite 7s ease-in-out,
  ),
  '190': (
    translation: translateX(-190px),
    animation: oscillating-text-190 infinite 7s ease-in-out,
  ),
  '200': (
    translation: translateX(-200px),
    animation: oscillating-text-200 infinite 7s ease-in-out,
  ),
  '210': (
    translation: translateX(-210px),
    animation: oscillating-text-210 infinite 8s ease-in-out,
  ),
  '220': (
    translation: translateX(-220px),
    animation: oscillating-text-220 infinite 8s ease-in-out,
  ),
  '230': (
    translation: translateX(-230px),
    animation: oscillating-text-230 infinite 8s ease-in-out,
  ),
  '240': (
    translation: translateX(-240px),
    animation: oscillating-text-240 infinite 8s ease-in-out,
  ),
  '250': (
    translation: translateX(-250px),
    animation: oscillating-text-250 infinite 9s ease-in-out,
  ),
  '260': (
    translation: translateX(-260px),
    animation: oscillating-text-260 infinite 9s ease-in-out,
  ),
  '270': (
    translation: translateX(-270px),
    animation: oscillating-text-270 infinite 9s ease-in-out,
  ),
  '280': (
    translation: translateX(-280px),
    animation: oscillating-text-280 infinite 9s ease-in-out,
  ),
  '290': (
    translation: translateX(-290px),
    animation: oscillating-text-290 infinite 10s ease-in-out,
  ),
  '300': (
    translation: translateX(-300px),
    animation: oscillating-text-300 infinite 10s ease-in-out,
  ),
);

@each $overflow, $value in $text-overflow-values {
  @keyframes oscillating-text-#{$overflow} {
    5% {
      transform: translateX(0);
    }
    45% {
      transform: map-get($value, translation);
    }
    55% {
      transform: map-get($value, translation);
    }
    95% {
      transform: translateX(0);
    }
  }

  .oscillating-text-#{$overflow}:hover {
    animation: map-get($value, animation);
  }
}
