.uploader {
  border-radius: 0.3125rem;
  min-width: 18.75rem;
  width: 100%;
  max-width: 575px;

  &.upload-complete {
    flex: 1;
  }

  .upload-message {
    margin: 1.5rem 0 0.5rem;
    font-size: $small-font-size;

    .specific-error-message {
      text-align: center;
    }

    .zylo-icon {
      margin-right: 0.5rem;
    }
  }

  .uploader-title {
    margin-bottom: 24px;
    font-size: 19px;
    font-weight: 400;
  }

  .uploader-instructions {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .uploader-progress-container {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    width: 100%;
  }

  .main-instructions {
    margin: 0.75rem 0 0;
    font-size: 14px;
    font-weight: 400;
    color: $black;
  }

  .secondary-instructions {
    color: $blue;
  }

  .button-container {
    margin-left: 60px;

    .upload-your-files {
      margin-right: 6px;
      padding: 0.5rem 0;
      font-size: $small-font-size;
      font-weight: 700;
      color: $black;
    }

    .button {
      margin-top: 12px;
    }
  }

  .close-document-upload {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .dropzone {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 10px;

    .zylo-icon svg {
      stroke: $blue;

      &:nth-child(4) {
        stroke: $darkgray;
      }
    }

    p {
      text-align: center;
    }
  }

  // animate the transition
  .uploader-progress-container {
    width: 18.75rem;
    overflow-y: auto;
    padding: 0.625rem;
  }
}

.dropzone,
.no-preview-container {
  position: relative;
  height: 11rem;
  width: 11rem;
  margin: 0.9375rem 1.25rem;
  padding: 1.5625rem;
  color: $darkgray;
  background: lighten($lightgray, 10%);
  border: 0.125rem dashed $darkgray;
  border-radius: 0.3125rem;
}

.file-preview {
  border-top-right-radius: 0.3125rem;
  border-top-left-radius: 0.3125rem;
  height: 100%;
  padding: 0.625rem 1.25rem 0 1.25rem;
  width: 100%;

  svg {
    margin: 0.75rem 0;
  }

  object {
    border: none;
    border-radius: 0.3125rem;
    height: 100%;
    width: 100%;
  }

  p {
    font-size: $extra-small-font-size;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0.625rem 0;
  }
}

.no-preview-container {
  .no-preview-instructions {
    color: $dark;
    font-size: $extra-small-font-size;
  }

  p {
    text-align: center;
  }
}

.accepted-file-types-tooltip {
  .accepted-file-types {
    margin-bottom: 4px;
    font-weight: 500;
  }

  ul {
    margin-left: 20px !important;
    list-style: disc !important;
  }
}
