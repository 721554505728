.form-header {
  padding: 5px 23px;
  border-bottom: $default-border;

  .form-header-title div,
  .form-header-actions .btn {
    @include vcenter;
  }
  .form-header-title div {
    font-size: $medium-font-size;
  }
  .form-header-actions .btn {
    display: inline-block;

    &.btn-action {
      float: right;
      width: auto;
      padding: 4px 25px;
    }

    &.btn-link {
      color: $darkgray;
      padding: 6px 20px;
    }
  }
}

label {
  margin: 0;
  color: $darkgray;
  font-weight: 400;
  font-size: 13px;
  padding-bottom: 4px;
}

input,
textarea {
  &::-webkit-input-placeholder {
    padding: 0 0 0 3px;
  }
  &::-moz-placeholder {
    padding: 0 0 0 3px;
  }
  &:-ms-input-placeholder {
    padding: 0 0 0 3px;
  }

  &::placeholder {
    color: $gray;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .input-group-prepend,
  .input-group-postpend {
    margin-right: -1px;
    padding: 0.3125rem 0.75rem 0.375rem 0.75rem;
    border: 1px solid $darkgray;
  }

  .input-group-prepend {
    border-right: 0;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .input-group-postpend {
    border-left: 0;
    border-radius: 0 0.25rem 0.25rem 0;
  }

  &.disabled {
    .input-group-prepend,
    .input-group-postpend {
      background-color: $color-100;
      border-color: $color-200;
      cursor: not-allowed;
    }
  }

  .input-group-children {
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    font-size: 0.9rem;
    font-weight: 400;
    flex: 2;
    border-radius: 0;
    height: 100%;
    > * {
      border-radius: 0 !important;
      flex-basis: 100%;
    }
  }

  .input-group-content {
    margin: 2px 0 0 0;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    color: $darkgray;
    font-size: 0.9rem;
    font-weight: 400;
  }
  > .form-element {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

input,
.form-element,
.input-group .form-element {
  padding: 6px 8px;
  font-weight: 400;
  border: 1px solid $darkgray;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #007a79;
  }
  &.valid {
    border-color: #006060;
  }
  &.error {
    border-color: #cc0000;
  }
}

textarea {
  padding: 10px 15px;
  line-height: 18px;
}

.option-picker {
  .labels {
    padding: 0 2% 0 0;
    .zylo-icon.icon-close {
      padding: 2px;
      margin: 0 0 0 2px;
      border-radius: 50%;
      border: 1px solid $darkgray;
      &:hover {
        border-color: $lightgray;
      }
    }
  }
  .option {
    position: relative;
    padding: 5px 0;
    .zylo-icon.icon-delete {
      @include absvCenter;
      top: 48%;
      right: 12%;
      display: none;
    }
    &:hover .zylo-icon.icon-delete {
      display: block;
    }
  }
  input[type='text'] {
    width: 90%;
  }
  .radio-wrapper {
    width: 10%;
    margin: 9px auto 0 auto;
    text-align: center;

    .radio-label .radio-fill {
      display: block;
      margin: 0 auto;
    }
  }
  .add-option {
    color: $darkgray;
    padding: 0;
    font-size: 0.9rem;
    text-decoration: none;

    &:hover {
      color: $lightgray;
    }
    .zylo-icon {
      margin: 0 5px 0 0;
    }
  }
}

.read-only-credential {
  max-width: 100% !important;
}

.copy-text-input {
  display: flex;
  flex-flow: row nowrap;
  input[type='text'] {
    flex-basis: 100%;
    flex-grow: 2;
    flex-shrink: 2;
    order: 1;
  }
  .copy-btn + input[type='text'] {
    padding-right: 32px;
  }
  .copy-btn {
    position: absolute;
    right: 0px;
    top: 22px;
  }
}

.button-action-icon-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  input[type='text'],
  input[type='password'] {
    flex-basis: 100%;
    flex-grow: 2;
    flex-shrink: 2;
    order: 1;
  }
  .button-action-icon-container {
    position: absolute;
    right: 0px;
    top: 22px;
  }
}

.autosize-textarea {
  line-height: 8px;
  position: relative;

  .form-element {
    width: 100%;
    padding: 6.5px 8px;
    min-height: 40px;
    line-height: 24px;
  }

  .zylo-icon {
    position: absolute;
    bottom: 9px;
    right: 24px;
  }
}

.number-input-field {
  display: flex;
  width: 100%;
}
