.dynamic-table-container {
  position: relative;
  flex: 1;
  height: 100%;
  min-height: 533px;
  margin: 0;
  white-space: nowrap;
  background: lighten($lightgray, 10%);
  border: none;
  border-radius: 0.25rem;
  overflow: hidden;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .data-table > div > div:nth-child(2) {
    top: 1px;
  }

  .ReactVirtualized__Grid {
    background: lighten($lightgray, 10%);
    &.dynamic-table-second-column {
      overflow-y: overlay !important;
    }

    &:first-child .dynamic-table-header:first-child .numerical-cell {
      justify-content: flex-start;
      text-align: left;
    }

    &:focus {
      outline: none;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      background: $white;
      border-right: $default-border;
      box-shadow: -11px -11px 3px 9px rgba(0, 0, 0, 0.75);
      overflow: visible !important;
    }
  }

  .dynamic-table-header {
    position: relative;
    color: $darkgray;
    text-align: left;
    font-size: $small-font-size;
    font-weight: 400;
    border-bottom: $default-border;
    transition: background-color 0.4s;

    .zylo-icon:not(.sort-icon) {
      margin-right: 0.25rem;
    }

    & > div {
      align-items: center;
    }

    & > div:not(.zylo-tooltip),
    & > .zylo-tooltip > span > div {
      width: 100%;
      padding: 5px 28px;
    }

    span {
      @include no-select;
    }
    .header-icon {
      margin-right: 4px;
    }
    .sort-icon {
      margin: 0 0 0 5px;
    }

    &.sort-active {
      color: $blue;
    }

    &.has-tooltip:hover {
      background: $hoverlightblue;
    }
  }

  .dynamic-table-cell {
    padding: 5px 0px;
    font-weight: 400;
    border-bottom: $default-border;

    & img {
      max-height: 40px;
    }

    &.clickable {
      cursor: pointer;

      * {
        cursor: pointer !important;
      }
    }

    > * {
      padding: 0 28px;
    }

    .tag {
      height: 30px;
      white-space: nowrap;
      overflow: hidden;
    }

    &.column-0.numerical-cell {
      justify-content: flex-start;
      text-align: left;
    }

    &.app-row {
      background: $backgroundgray;

      .subscription-cell-name-container {
        margin-left: 75px;
      }
    }
  }

  .dynamic-table-first-header,
  .BottomLeftGrid_ScrollWrapper {
    transition: box-shadow 1s;
  }

  &.lock-first-column {
    .dynamic-table-first-header {
      box-shadow: 5px -2px 5px -2px $lightgray;
    }

    .BottomLeftGrid_ScrollWrapper {
      box-shadow: 5px 0px 5px -2px $lightgray;
    }
  }

  .locked-column {
    border-right: $default-border;
  }

  .dynamic-table-first-column::-webkit-scrollbar {
    display: none;
  }

  .dynamic-table-first-column {
    scrollbar-width: none;
  }

  .no-table-results {
    height: 100%;
    text-align: center;
    font-weight: 500;
  }

  .dynamic-table-first-column,
  .dynamic-table-headers {
    .no-table-results {
      display: none !important;
    }
  }

  .loader-container {
    z-index: 2;
  }
}

.dynamic-table-header-text {
  display: inline;
}

// themes
.dynamic-table-container.compact {
  border: none;
  .dynamic-table-header > .flex-container,
  .dynamic-table-cell {
    justify-content: flex-end;
  }
  //todo add class to signify first column is locked
  .dynamic-table-header.locked-column > .flex-container,
  .dynamic-table-cell.column-0 {
    justify-content: flex-start;
  }
  .dynamic-table-header,
  .dynamic-table-cell {
    font-size: $extra-small-font-size;
    font-weight: 100;
    > div:not(.zylo-tooltip) {
      padding: 0 14px;
    }
  }
  .dynamic-table-cell.column-0 > *,
  .dynamic-table-header:first-child > .flex-container {
    margin-left: 0;
  }
  .dynamic-table-cell.last-in-row > *,
  .dynamic-table-header:last-child > .flex-container {
    margin-right: 0;
  }
  .dynamic-table-cell,
  .dynamic-table-header {
    padding: 0;
  }
  .dynamic-table-headers,
  .dynamic-table-cell,
  .locked-column,
  .dynamic-table-header {
    border: none;
  }
}
