.insights-banner {
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  align-content: center;

  .light-box {
    height: 100%;
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    position: relative;
    margin: auto 10px auto 0;
    .zylo-icon {
      @include absCenter;
      margin-top: -4px;
    }
  }

  .insights-arrow {
    border: none;
    background: transparent;
    border-radius: 0;
    margin: auto 0;
    height: 100%;
    padding: 10px;
    transition: all $default-transition;
    @include inView;
    &:not(:disabled) {
      cursor: pointer;
      &:hover {
        background: $lightgray;
      }
    }
    &.arrow-left .zylo-icon {
      transform: rotate(180deg);
      margin-left: -1px;
    }
    &.arrow-right .zylo-icon {
      margin-left: 1px;
    }
    &.hide {
      @include invisible;
    }
  }

  .insight-content {
    flex: 2 2 auto;
    height: 100%;
    padding: 0 8px;
  }
}

.okta-insight {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 100%;
  position: relative;
}

.insight-icons-wrapper {
  display: inline-flex;
  flex-flow: row-reverse nowrap;
  padding: 5px;
  margin: auto 10px;

  .insight-app-icon {
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 50%;
    border: 1px solid $white;
    display: inline-block;
    overflow: hidden;
    background-color: $white;
    &:not(:first-child) {
      margin-left: -5px;
    }

    .app-logo-container {
      width: 100%;
      height: 100%;
      display: block;
      img {
        width: 25px;
        height: 25px;
        @include absCenter;
      }
    }
  }

  .additional-apps {
    margin-left: 5px;
  }
}

/**
 * Insights-style Icons
 */
.zylo-icon.insights-icon {
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
  &.icon-money {
    background: $hoverlightblue;
  }
  &.icon-calendar {
    background: lighten($purple-hover, 20%);
    svg {
      stroke: $purple;
    }
  }
  &.icon-user {
    background: $backgroundgray;
    svg {
      stroke: $turquoise;
    }
  }
}

/**
 * Saved Search sidebar on Subscriptions Grid
 */
.subscriptions-saved-filters {
  min-width: 400px;
  .filter-list-item {
    padding: 1.25rem 1rem;
    margin: 0 16px;
    border-bottom: $default-border;
  }
}

/**
 * Insights v2 common styles
 */
.insight-container {
  h3,
  h4 {
    font-size: $default-font-size;
    font-weight: 400;
  }
  article,
  div {
    &[class*='-opportunity'] {
      width: 100%;
    }
  }
  .insights-header > header {
    width: 100%;
    padding: 0.625rem 0;
  }
  &.opportunity-list {
    flex-direction: column;
  }
  &.opportunity-carousel .insights-header {
    flex-direction: column;
    padding: 10px 20px;
  }
  .insight-name {
    margin-left: 5px;
  }
}
.insight-opportunities {
  &.opportunities-carousel {
    width: 100%;
    padding: 1.5rem 1.5rem 0.625rem;
  }
  &.opportunities-list {
    > * {
      border: $default-border;
      padding: 0 5px 10px 5px;
      margin: 0 0 10px 0;
    }
    .opportunity-title {
      font-size: 1rem;
      margin: 10px 10px 20px 10px;
    }
  }
  .opportunity-title {
    color: $dark;
  }
}

/**
 * Insights Search sidebar on Subscriptions Grid
 */
.insights-sidebar {
  min-width: 400px;
  height: 100%;
  .insights-sidebar-body {
    padding: 10px 10px 0 10px;
  }
  .app-logo-container {
    margin-right: 0.75em;
  }
  .insight-name,
  .insight-stat {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    color: $dark;
  }
}

.insights-tile {
  &.card {
    padding: 0;
    overflow: hidden;
  }
  .insights-header {
    padding: 1.25rem 1.5rem 0.625rem;
    background: darken($blue, 10%) url('../assets/img/insights_tile_background_overlay.svg')
      no-repeat;
    background-position-x: 3%;
    background-position-y: 70%;
    color: $white;
    > header {
      width: 100%;
      margin: 0.625rem 0 0;
      padding: 1.25rem 0;
      border-bottom: 1px solid;
      margin-bottom: 0.625rem;
    }
  }
  .insight-stat {
    font-size: 0.9rem;
    align-self: flex-start;
  }
  .insight-description {
    width: 100%;
    font-size: $small-font-size;
    font-weight: lighter;
    line-height: 1.25rem;
    padding: 0.625rem 0 0.3125rem;
  }
  .opportunity-title {
    padding: 0.625rem 0;
    font-weight: bolder;
  }
}

.insights-carousel {
  height: auto;
}

@import './opportunities/usageOpportunity';
@import './opportunities/functionalityOpportunity';
