/**
 * TODO: check other files that set rules for .details-sidebar and dedupe
 *       double-check that there aren't two different use-cases for .details-sidebar
 *       (that all of these scss rules are applying to the same component)
 *       files: details.scss, application-details.scss employees.scss
 */
.details-sidebar {
  position: relative;
  flex: 0 0 290px;
  min-height: 100%;
  color: $navy;
  background-color: $white;
  transition: flex 0.3s linear;

  &.left-sidebar {
    overflow: auto;
    margin-left: auto;
    border-right: $default-border;

    // accounts for combined sidebars at narrow window widths
    .right-sidebar .sidebar-section {
      border-top: $default-border;
    }
    .right-sidebar {
      overflow: visible;
    }
  }

  &.right-sidebar {
    border-radius: 0 10px 10px 0;

    .sidebar-btn {
      position: absolute;
      top: 1px;
      left: 1px;
      transform: translateX(-101%);
      padding: 10px 5px;
      background: $white;
      border: $default-border;
      border-right-width: 0;
      border-radius: 5px 0 0 5px;
      visibility: visible;

      &:hover {
        background: $backgroundgray;
      }

      img {
        max-width: 20px;
        transition: 1s;
      }
    }

    .right-sidebar-content {
      overflow: auto;
    }
  }

  &.collapse-sidebar {
    flex: 0 0 65px;
    width: 25%;
    border: none;
  }

  //  Supports the behavior of the right sidebar on smaller screens
  @media (max-width: 1300px) {
    &.expanded-sidebar-small {
      position: absolute;
      left: calc(100% - 300px);
      max-width: 300px;
      z-index: 10;
    }

    &.collapse-sidebar {
      position: absolute;
      left: calc(100% - 65px);
      max-width: 65px;
      z-index: 10;
    }
  }

  .sidebar-section {
    position: relative;
    padding: 16px;
    @include edit-mode-indicator($pink);

    &:not(:first-child) {
      border-top: $default-border;
    }

    .unsaved-changes-message {
      margin-right: auto;
      color: $pink;
      font-size: 0.65rem;
      white-space: nowrap;
    }

    .sidebar-section-button-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .sidebar-subsection {
    *:nth-child(2) {
      flex: 1;
    }
  }

  .sidebar-title {
    margin-bottom: 16px;
    color: #007a79;
    display: flex;
    justify-content: space-around;
    @extend .section-header;

    &.justify-start {
      justify-content: flex-start;
    }

    .zylo-icon:first-child {
      margin-right: 12px;
    }
  }

  .metric-label {
    margin-bottom: 4px;
    @extend .body-gray;
  }

  .metric-value {
    margin-bottom: 16px;
    @extend .body-black;

    .zylo-icon {
      margin-left: 8px;
    }

    &:hover {
      .external-link {
        @extend .body-blue-bold;

        .zylo-icon svg {
          stroke: $blue;
        }
      }
    }
  }
}

.collapsed-sidebar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  padding: 20px;
  border-bottom: $default-border;
}

//  Supports the behavior of the right sidebar on smaller screens
@media (max-width: 1300px) {
  .sidebar-spacer {
    min-width: 65px;
  }
}
