@import '../mixins/mixins';

.browser-warning-page {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $darkgray;

  .zylo-logo {
    width: 300px;
    margin-bottom: 40px;
  }
}
