.filter-bar {
  flex-flow: row nowrap;
  padding: 5px 15px;
  border-top: $default-border;
  min-height: 40px;
  border-bottom: $default-border;
  position: relative;
  overflow: visible;

  &.borderless {
    border-top: none;
    border-bottom: none;
  }

  .spacer {
    flex-shrink: 1;
    flex-grow: 0;
    display: inline-block;
    flex-basis: 20px;
    height: auto;
  }

  & > * {
    flex-grow: 1;
  }
  .flex-grow-none {
    flex-grow: 0;
  }
  .flex-grow-max {
    flex-grow: 2;
  }

  .filter-tokens-container {
    .filter-token {
      margin: 4px 8px;
      font-size: $small-font-size;
      font-weight: 400;
      border-radius: 4px;
      background: $blue;
      color: $white;
      padding: 2px 8px;

      .remove-filter {
        margin-left: 8px;
        padding: 1px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }

  .show-guest-users-wrap .zylo-tooltip {
    margin: 0 8px 0 5px;
  }

  .filter-bar-search-wrap {
    margin-right: 28px;
    input[name='search'] {
      min-width: 260px;
    }
  }

  .buttons-container {
    margin-right: 28px;

    & > *:not(:first-child) {
      margin-left: 12px;
    }
  }
}
