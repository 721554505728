.placeholder-screen {
  display: block;
  margin-top: 3rem;
  text-align: center;

  & > * {
    margin: 1.5rem auto;
  }

  .heading-custom {
    font-size: $large-font-size;
    color: $navy;
    font-weight: 200;
  }

  .subheading-custom {
    color: $darkgray;
    max-width: 450px;
    font-weight: 400;
    padding: 0 25px;
  }

  .btn {
    margin-top: 2rem;
    float: none !important;
  }
}
