.tabs {
  width: 100%;

  // The Tabs that appear as floating buttons
  &.standard-tabs .topbar {
    .tabs-labels {
      display: flex;
      margin: 0 auto;
      padding: 0;

      .tab-label {
        min-width: 120px;
        height: 40px;
        padding: 16px;
        font-size: $small-font-size;
        font-weight: 400;
        white-space: nowrap;
        background: transparent;
        border: none;
        box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.33);
        cursor: pointer;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        &:hover:not(.active) {
          color: $blue;
          .tab-icon g {
            fill: $blue !important;
          }
        }
        &.active {
          background: $lightgray;
          border: $default-border;
          box-shadow: none;
          cursor: default;
          .tab-icon g {
            fill: $lightgray !important;
          }
        }
      }
    }
  }

  // Same as .details-tabs but without bottom border
  &.details-tabs.separated-tabs {
    .topbar {
      border-bottom: none;
    }

    .tabs-labels {
      justify-content: flex-start;
    }

    .tab-label {
      padding: 0 0 0.8rem 0;
      color: $darkgray;
      font-size: $default-font-size;

      &:first-child,
      &:last-child {
        border-radius: 0;
      }

      &:focus {
        outline: none;
        color: $blue;
      }

      &.active {
        color: $navy;
        background: transparent;
        border: none;
        border-bottom: 2px solid $blue;
        box-shadow: none;
        cursor: pointer;
      }
    }
  }

  // The Tabs that appears are left floated sections on top of a full width bottom border
  &.details-tabs {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    .topbar {
      min-height: 0;
      margin: 0;
      background: transparent;

      .tabs-labels {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        .tab-label {
          border-bottom: 4px solid rgba(0, 0, 0, 0);
          color: #333;
          display: block;
          font-family: 'Quicksand';
          font-weight: 400;
          padding: 0 0.25rem 0.5rem;
          margin: 0.5rem 1rem 0 0;
          font-size: 1rem;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            color: #007a79;
            .tab-icon g {
              fill: #007a79 !important;
            }
          }

          &.active {
            color: #006060;
            border-color: #006060;
            cursor: default;
            font-weight: 500;

            .tab-icon g {
              fill: #006060 !important;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .details-tab-content {
      flex: 2;
    }
  }

  .tabs-content-wrapper {
    position: relative;
    display: flex;
    flex: 1 1 0;
    height: 100%;
  }
  .tabs-content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    padding: 1rem 0 0;
    background: $backgroundgray;

    > * {
      flex: 1 0 auto;
      max-width: 100%;
      display: flex;
      flex-direction: column;
    }
    .tab-pane {
      height: 100%;
    }

    .tabs-outer-conter,
    .tabs-inner-container {
      height: 100%;
    }

    .tabs-inner-container {
      overflow: hidden;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.tab-content-section {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: $white;
  border: $default-border;
  border-radius: 10px;
  min-height: 100%;
  overflow: visible;

  .tab-content-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    padding: 16px 20px;

    .tab-content-section-title {
      display: flex;
      flex: 1;
      align-items: center;
      font-size: $default-font-size;
      font-weight: 400;
    }
  }

  .dynamic-table-container {
    height: calc(100vh - 330px);
    border: none;
  }
}
