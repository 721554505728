.search-summary {
  display: flex;
  align-items: center;
  min-height: 100px;
  max-height: 100px;
  margin: 20px 36px 0;
  background: $white;
  border-radius: 15px;
  border: $default-border;
  overflow: hidden;
  @include fade-in;

  .count {
    width: 10%;
    text-align: center;
  }

  .totals {
    width: 90%;
    border-left: $default-border;
  }

  .summary-item-label {
    font-size: $medium-font-size;
    font-weight: 400;
    color: $darkgray;
  }

  .summary-item {
    padding: 10px 0;

    .summary-item-number {
      display: block;
      margin: 0 0 -10px 0;
      color: $blue;
      font-size: $large-font-size;
      font-weight: 100;
      text-align: center;
    }
  }
}
