.zylo-checkbox-wrapper {
  padding: 11px 0 5px;
  $checkbox-color: $blue;

  .zylo-checkbox-label {
    display: inline-flex;
    align-items: center;
    width: auto;
    color: $black;
    cursor: pointer;
    position: relative;
    text-transform: capitalize;
    line-height: 18px;

    .zylo-check {
      display: inline-block;
      position: relative;
      background-color: transparent;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      transform-origin: center;
      border: 2px solid $darkgray;
      border-radius: 1.5px;
      vertical-align: -5px;
      top: -1px;
      transition: background-color 150ms 200ms,
        transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);

      &::before,
      &::after {
        content: '';
        width: 0px;
        height: 3px;
        border-radius: 0px;
        background: $checkbox-color;
        position: absolute;
        transform-origin: 0% 0%;
      }

      &::before {
        transform: rotate(45deg);
        top: 4px;
        left: 3px;
        transition: width 50ms ease 50ms;
      }

      &::after {
        transform: rotate(315deg);
        top: 10px;
        left: 5px;
        transition: width 50ms ease;
      }
    }

    & > span[role='tooltip'] {
      display: inline-flex;
    }

    .zylo-check-bubble {
      border-radius: 7px !important;

      &::before,
      &::after {
        content: unset;
      }
    }
  }

  .zylo-checkbox {
    display: none;

    &:checked + .zylo-checkbox-label,
    &:checked + .zylo-checkbox-label:hover {
      .zylo-check {
        &::after,
        &::before {
          background: $checkbox-color;
          transition: width 150ms ease 100ms;
        }

        &::after {
          width: 20px;
        }
        &::before {
          width: 8px;
        }
      }
    }

    &:disabled {
      & + .zylo-checkbox-label {
        cursor: default;
        color: $darkgray;

        .zylo-check {
          border-color: $darkgray;
          cursor: default;

          &::after,
          &::before {
            display: none;
          }
        }

        &:hover .zylo-check {
          &::after,
          &::before {
            display: none;
          }
        }
      }

      &:checked + .zylo-checkbox-label,
      &:checked + .zylo-checkbox-label:hover {
        .zylo-check {
          display: inline-block;

          &::after,
          &::before {
            display: inline-block;
            background-color: $darkgray;
          }
        }
      }
    }
  }

  &.large {
    .zylo-checkbox-label {
      line-height: 22px;

      .zylo-check {
        top: 0px;
        height: 22px;
        width: 22px;

        &::before,
        &::after {
          height: 6px;
        }
        &::before {
          top: 6px;
          left: 5px;
        }
        &::after {
          top: 15px;
          left: 6px;
        }
      }
    }

    &:hover .zylo-check {
      &::before {
        width: 12px;
      }
      &::after {
        width: 24px;
      }
    }

    .zylo-checkbox:checked + .zylo-checkbox-label,
    .zylo-checkbox:checked + .zylo-checkbox-label:hover {
      .zylo-check {
        &::after {
          width: 24px;
        }
        &::before {
          width: 12px;
        }
      }
    }
  }

  &.no-label {
    .zylo-checkbox-label {
      width: 18px;

      .zylo-check {
        margin: 0;
      }
    }
  }
}

.zylo-checkbox-wrapper-condensed {
  @media (min-width: 1570px) {
    width: 13% !important;
  }
  @media (max-width: 1570px) {
    width: 17% !important;
  }
  @media (max-width: 1285px) {
    width: 20% !important;
  }
}
