.nav {
  padding-left: 0;
  margin-bottom: 0;
}

.nav-section-main {
  font-weight: 200;
}

.nav-section-header {
  color: $darkgray;
  font-size: $small-font-size;
  font-weight: 400;
  margin-bottom: 8px;
}

.external-link {
  display: flex;
  align-items: center;
  color: $blue;

  &.inline {
    display: inline;
  }

  .zylo-icon {
    margin-left: 5px;
  }

  &:hover {
    color: $blue;
  }
}

.nav-pills {
  height: 64px;

  .nav-item {
    height: 64px;
    margin: 0 10px !important;
    transition: $default-transition;

    .nav-link {
      height: 46px;
      padding: 0.5em 1em;
      font-weight: 400;
      color: $darkgray;
      transition: $default-transition;

      &:hover {
        color: white;
        background: $transparentgray;
      }

      &.active {
        color: $white;
        background: $navy;
        border-radius: 0;

        &:hover {
          background: none;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $lightgray;
      }

      &.disabled:hover {
        color: $lightgray;
      }
    }

    .beta-indicator {
      margin-left: 10px;
      padding: 2px 12px;
      font-size: $extra-small-font-size;
      color: $white;
      background: $lightblue;
      border-radius: 12px;
    }
  }
}
