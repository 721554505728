.number-filter {
  padding: 8px;

  .Select {
    border-color: $lightgray;

    .Select-placeholder,
    .Select-option {
      font-size: $small-font-size;
    }
  }

  .number-filter-inputs {
    padding: 8px 0 0;

    .number-input-field {
      width: 112px;

      input {
        max-width: 100%;
        border-color: $lightgray;
      }
    }

    .number-filter-divider {
      padding: 8px;
    }
  }
}
