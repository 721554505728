.search-filter-header {
  width: 100%;
}

.expandable-content + .expandable-content {
  border-top: none;
}

.expandable-content {
  border-top: 2px solid $lightgray;
  border-bottom: 2px solid $lightgray;
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: max-height 0.2s ease-in;

  .collapse-button-container {
    background-color: transparent;
    padding-bottom: 1rem;
    position: relative;
  }

  .button,
  .zylo-icon {
    @include fade-in;
  }

  .view-details-button {
    position: absolute;
    top: 1.3rem;
    right: 1.7rem;
  }
}

.scrollable-content {
  height: 100%;
  overflow-y: auto;
}

.scroll-container {
  position: fixed;
  top: 175px;
  width: 100%;
  overflow-y: auto;

  .primary-content {
    overflow-x: hidden;
  }
}

.page-contents {
  height: 100%;
}
.page-title {
  padding: 1rem 0;
  font-size: $extra-medium-font-size;
  font-weight: 400;
  color: $blue;
}

.zylo-breadcrumb {
  justify-content: flex-start;
  list-style: none;

  .back-to-link {
    display: inline-block;
    color: $darkgray;
    font-size: $small-font-size;
    font-weight: 200;

    span {
      display: inline-block;
      margin: 0 0 0 5px;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
      color: $blue;

      svg {
        stroke: $blue;
      }
    }
  }
}

.browser-warning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33px;
  line-height: 33px;
  z-index: 42;
  background: $red;
  color: $white;
  text-align: center;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.75);

  span {
    padding: 0 10px;
    text-decoration: underline;
    cursor: pointer;
  }
}
