@import '~react-dates/css/variables.scss';
@import '~react-dates/css/styles.scss';

.date-picker {
  width: 142px;
  border: none;
  overflow: hidden;
  &.errors-exist {
    .date-picker-input-container {
      .date-picker-input {
        border-color: $red;
      }
    }
  }

  .date-picker-input-container {
    position: relative;
    max-width: 100%;

    .date-picker-input {
      width: 100%;
      padding: 6px 8px;
      border: 1px solid $darkgray;
      transition: border-color 0.5s;
    }
  }

  .zylo-icon {
    @include absvCenter;
    right: 12px;

    &.icon-close:hover {
      cursor: pointer;

      svg {
        stroke: $blue;
      }
    }
  }

  .DayPicker {
    position: absolute;
    z-index: 2;
    margin-top: 4px;
    border: $default-border;
    box-shadow: 1px 2px 5px $lightgray;
    overflow: hidden;

    .DayPickerNavigation--horizontal .DayPickerNavigation__prev,
    .DayPickerNavigation--horizontal .DayPickerNavigation__next {
      border: none;

      &:hover svg {
        stroke: $blue;
      }
    }

    .CalendarDay {
      border: none;
      border-radius: 19px;

      &.CalendarDay--today {
        color: $white;
        background: lighten($turquoise, 20%);

        &:hover {
          background: lighten($turquoise, 10%);
        }

        &.CalendarDay--blocked {
          background: $lightgray;
        }
      }

      &.CalendarDay--selected {
        background: lighten($blue, 10%);

        &:hover {
          background: $blue;
        }
      }

      &.CalendarDay--blocked .CalendarDay__button:hover {
        cursor: not-allowed;
      }
    }

    .DayPickerKeyboardShortcuts__show--bottom-right {
      display: none;
    }
  }

  &.date-picker__focused {
    .date-picker-input {
      border-color: $blue;
    }

    .zylo-icon svg {
      stroke: $blue;
    }
  }

  &.date-picker__read-only {
    .date-picker-input {
      border: none;
      cursor: default;
    }

    .zylo-icon {
      display: none;
    }
  }
}
