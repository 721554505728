.plans-breakdown-chart {
  flex-flow: row wrap;

  .breakdown-chart {
    flex-basis: 60%;
    flex-grow: 1;
    min-width: 160px;

    .donut-center-percentage {
      font-size: 2.5rem;
    }
    .donut-center-value {
      display: none;
    }
  }

  .breakdown-text {
    flex-basis: 40%;
    flex-grow: 1;
    padding: 0 5px;
    font-weight: 200;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;

    .text-row {
      .metric-label:first-letter {
        text-transform: capitalize;
      }

      &.purchased {
        order: 1;
      }

      &.provisioned {
        order: 2;

        .metric-value {
          color: $blue;
        }
      }

      &.active {
        order: 3;

        .metric-value {
          color: $green;
        }
      }
      &.inactive {
        order: 4;

        .metric-value {
          color: $red;
        }
      }
    }
  }
}
