.numerical-cell {
  justify-content: flex-end;
  text-align: right;

  .clickable {
    cursor: pointer;
    color: $blue;

    &:hover {
      color: darken($blue, 10%);
    }
  }
}
.action-cell > .popover-menu {
  float: right;
}

.action-confirmation-cell {
  & > div {
    display: flex;
    align-items: center;
  }
}

.input-cell {
  .zylo-form {
    display: flex;
    width: 200px;
    padding: 0;
    margin: 0;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    .zylo-form-field {
      min-height: auto;
      margin: auto auto auto 0;
      padding: 0px;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
      .zylo-form-message {
        height: auto;
      }
      input {
        height: 100%;
      }

      .form-element {
        width: 100px;
      }

      .undo-autosave {
        margin-left: 8px;
      }
    }
  }
}

.subscription-cell {
  .flag-container {
    min-width: 24px;
    position: relative;
    margin: 0 5px 0 0;

    .new-app {
      margin: auto 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $turquoise;
    }

    .insights-popover {
      top: -4px;
    }
  }

  .subscription-cell-name-container {
    max-width: 100%;
    width: 100%;

    .subscription-cell-name {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.not-clickable {
    .app-logo-container .app-logo,
    .subscription-cell-name-container .subscription-cell-name {
      cursor: default;
    }
  }
}

.name-cell {
  display: flex;
  justify-content: space-between;

  .employee-icon {
    height: 20px;
    width: 20px;
    margin-right: 0.55rem;
    font-size: $extra-small-font-size;
    color: $white;
    background: $blue;
    border-radius: 50%;
  }
}

.name-cell,
.subscription-cell {
  p {
    margin: 0;
  }
}

.name-cell .name-cell-name {
  color: -webkit-text;
}

.metadata-cell .zylo-icon {
  cursor: pointer;
}

.delete-cell {
  display: block;
  min-width: 191px;

  & > .zylo-icon {
    margin-right: 30px;
    cursor: pointer;
  }

  .confirm-action {
    padding-right: 30px;

    .confirmation-button {
      font-size: $medium-font-size;
      cursor: pointer;
    }

    .confirm-message {
      margin-right: 0.75em;
    }

    .confirm {
      margin-right: 0.5em;
      color: $green;
    }

    .cancel .cancel-icon {
      width: 1em;
      padding-bottom: 0.5em;
    }
  }
}

.handle-click-cell {
  margin-left: auto;
}

.reporting-action-cell {
  text-align: right;
}

.sidebar-trigger-cell {
  div,
  p {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
  p {
    margin: 0 0 0 5px;
  }
}

.icon-click-cell {
  .zylo-icon {
    margin-right: 12px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  &.multiple-icons {
    justify-content: space-between;
    padding: 0 !important;
    margin: 0 auto;

    .zylo-icon {
      margin-right: 0;
    }
  }
}

.highlight-cell {
  color: $blue;
  cursor: pointer;
}

.disable-highlight-hover {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.tooltipped-cell {
  div {
    display: inline-block;
  }
  span.zylo-tooltip {
    margin-left: 8px;
    svg {
      margin-top: -3px;
    }
  }
}

.condensed-company-app-selector-cell {
  min-width: 300px;
  max-width: 300px;
  padding: 0 15px;
}

.company-app-selector-cell {
  width: 100%;
  min-width: 350px;

  .Select-option {
    background: transparent;
    cursor: default;
    padding: 4px 0;
  }

  .selected-option-read-only {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .type-ahead {
    min-width: 300px;
    border: none;
    width: 100%;

    .app-logo-container {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      border-radius: 50%;

      .app-logo {
        padding: 0;
      }
    }

    .Select--single .Select-input {
      padding-left: 48px;
    }
  }

  .popover-menu > button {
    min-width: 2rem;
    min-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.select-cell {
  .Select {
    min-width: 400px;
  }
}

.ztable__cell {
  &--condensed {
    .app-logo-container {
      height: 18px;
      min-width: 18px;
      width: 18px;
    }

    .company-app-selector-cell {
      .type-ahead {
        .app-logo-container {
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
        }
      }
    }
  }
}
