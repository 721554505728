.create-saved-filter {
  display: flex;
  flex-direction: column;
  width: 25vw;
  position: relative;

  header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.75rem;
    padding: 0 2rem;

    h2 {
      margin: 0 0 2rem 0;
      padding: 0;
      border: 0;
      font-size: $extra-medium-font-size;
      font-weight: 285;
      text-align: left;
    }
  }
  .create-saved-filter__close-button {
    position: absolute;
    top: -5px;
    left: calc(100% - 20px);
  }

  .create-saved-filter__form-field {
    max-width: 100%;
    min-width: 100%;
    margin: 0 0 0.75rem 0;

    textarea {
      padding: 8px;
    }
  }

  & > div:nth-of-type(2) {
    display: flex;
    align-content: flex-start;
    width: 100%;
  }

  .create-saved-filter__checkbox {
    margin-bottom: 1rem;
  }

  .create-saved-filter__buttons-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 1rem;
    align-self: center;
  }
}
