.advanced-roles-selector {
  .role-container {
    min-width: 100%;
    margin-bottom: 8px;

    .role-label {
      font-weight: 400;
    }

    .segmented-selector-section {
      flex: 1;
    }

    .segmented-selector:not(.disabled) {
      .segmented-selector-section:last-child {
        &.active-segmented-selector-section {
          background-color: $darkgray;
        }

        &:not(.active-segmented-selector-section):hover {
          background-color: $lightgray;
        }
      }
    }
  }
}

.roles-summary {
  margin: 0 0 10px;
  padding: 8px 0;
  vertical-align: middle;
  font-size: $small-font-size;
  font-weight: 400;
  color: $darkgray;
  border-bottom: $default-border;

  & > .flex-container > * {
    white-space: nowrap;
  }

  .roles-summary-intro {
    margin-right: 5px;
  }

  .roles-summary-verb {
    margin-right: 5px;
    color: $blue;
  }

  .role {
    color: $navy;

    &.last-role {
      margin-right: 0;
    }
  }

  .roles-summary-comma {
    margin-right: 5px;
  }

  .roles-summary-and {
    margin: 0 5px;
  }
}

.validation-error .roles-summary {
  color: $red;
}
