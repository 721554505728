.type-ahead {
  position: relative;
  border: 1px solid $darkgray;
  border-radius: 4px;
  min-height: 42px;

  * {
    background: transparent !important;
  }

  &.read-only {
    &.multiple,
    .pill-container {
      border-color: transparent;
    }

    .Select-arrow-zone,
    .zylo-icon {
      display: none;
    }
  }

  .pill-container {
    flex-wrap: wrap;
    width: 100%;
    padding: 8px 8px 0 8px;
    border-bottom: 1px solid $darkgray;

    .type-ahead-pill {
      height: 32px;
      max-width: 100%;
      margin: 0 8px 8px 0;
      padding: 8px;
      color: $white;
      background: $blue !important;
      font-weight: 200;
      white-space: nowrap;
      border-radius: 6px;

      .pill-text {
        flex: 1 0 0;
        overflow: hidden;
      }

      .zylo-icon {
        margin-right: 0;
        margin-left: 8px;
        cursor: pointer !important;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .Select {
    border: none;
  }

  .type-ahead-text {
    flex: 2;
    font-size: $medium-font-size;

    .Select-control {
      background: transparent;
    }

    .Select-arrow-zone {
      @include fade-in;
    }

    &.single-value {
      position: absolute;
      width: 100%;
      border: none;
      border-radius: 0;

      .Select-input {
        padding: 0;
      }
      .Select-arrow-zone {
        display: none;
      }
    }
  }

  .dropdown-container {
    width: 100%;

    .Select.is-searchable {
      width: calc(100% - 48px);

      .Select-input {
        width: 100%;

        input {
          width: 100% !important;
          font-size: $default-font-size;
          border-radius: 0;
        }
      }

      .Select-menu-outer {
        background: $white !important;

        .Select-option * {
          pointer-events: none;
        }
      }
    }
  }

  &.multiple {
    flex-direction: column;
  }

  &.no-options .Select-arrow-zone {
    display: none;
  }

  &.is-focused {
    border-color: $blue;

    .type-ahead-text.single-value {
      display: none;
    }
  }

  &.disabled {
    background-color: $color-100;
    border-color: $color-200;
  }
}

.zylo-form-field.disabled .pill-container {
  border: none;
}
