$radio-base-size: 16px;
$radio-large-size: $radio-base-size * 1.2;

.radio-wrapper {
  position: relative;
  .radio {
    display: none;
  }

  & > span {
    position: absolute;
    top: 26.5px;
    left: 28px;
    font-size: $extra-small-font-size;
  }

  .radio-label {
    color: $black;
    cursor: pointer;
    font-size: $radio-base-size;

    .radio-fill {
      position: relative;
      margin: 0 10px 0 0;
      display: inline-block;
      width: $radio-base-size;
      height: $radio-base-size;
      border: 2px solid $darkgray;
      border-radius: 50%;
      background: transparent;

      &::after {
        content: '';
        position: absolute;
        left: 1px;
        top: 1px;
        height: $radio-base-size - 6;
        width: $radio-base-size - 6;
        background-color: $blue;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: all $default-transition;
      }
    }

    .zylo-tooltip {
      margin-left: 8px;
    }
  }

  .radio:checked + .radio-label .radio-fill::after {
    opacity: 1;
    visibility: visible;
  }

  .radio:disabled + .radio-label {
    cursor: not-allowed;

    .radio-fill {
      background: $lightgray;
    }
  }

  .radio:checked:disabled + .radio-label .radio-fill::after {
    background-color: $darkgray;
  }

  &.large {
    .radio-label {
      font-size: $radio-large-size;

      .radio-fill {
        width: $radio-large-size;
        height: $radio-large-size;

        &::after {
          height: $radio-large-size - 6;
          width: $radio-large-size - 6;
        }
      }
    }
  }
}

.radio-list {
  &.horizontal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .radio-option {
    margin: 2px 10px;
    flex-basis: auto;
  }
  button {
    margin: 2px 10px;
    font-size: 14px; // to be deleted on button standardization
  }
}

.radio-switch {
  padding: 4px 0;

  .switch-container {
    display: inline-block;
    background: $blue;
    border: 2px solid $blue;
    border-radius: 6px;
  }

  input[type='radio'] {
    display: none;
  }

  label {
    background: transparent;
    cursor: pointer;
    padding: 5px 12px 5px 10px;
    border-radius: 6px;
    font-size: $small-font-size;
    color: $backgroundgray;
  }

  input[type='radio']:checked + label {
    background: $white;
    color: $blue;
    cursor: default;
  }

  &.disabled {
    * {
      cursor: not-allowed;
    }

    input[type='radio']:checked + label {
      cursor: not-allowed;
    }

    .switch-container {
      background: $darkgray;
      border-color: $darkgray;
    }
  }
}
