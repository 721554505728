.segmented-selector {
  .segmented-selector-section {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    height: 36px;
    margin-left: -1px;
    padding: 0;
    color: $darkgray;
    background-color: $white;
    border: $default-border;
    cursor: pointer;
    transition: all 500ms ease;

    * {
      margin: 0 !important;
      pointer-events: none;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.empty-segmented-selector-section {
      background: transparent;
      border: none;
      cursor: default;
    }
  }

  &:not(.disabled) {
    .segmented-selector-section {
      &:not(.empty-segmented-selector-section):not(.active-segmented-selector-section):hover {
        background-color: $hoverlightblue;
      }

      &.active-segmented-selector-section {
        color: $white;
        background-color: $lightblue;
        cursor: default;
      }
    }
  }

  &.disabled {
    .segmented-selector-section {
      cursor: not-allowed;

      &.active-segmented-selector-section {
        color: $white;
        background-color: $lightgray;
      }
    }
  }

  &.status-selector {
    &:not(.disabled) {
      .segmented-selector-section {
        &.active-segmented-selector-section {
          background-color: #cb4f00;

          .zylo-icon {
            stroke: $white;
          }

          &:first-child {
            background-color: #007a79;
          }

          &:last-child {
            background-color: #cc0000;
          }
        }

        &:not(.active-segmented-selector-section):hover {
          &:not(:first-child):not(:last-child) {
            background-color: #cb4f00;
          }
          .zylo-icon {
            stroke: $white;
          }

          &:first-child {
            background-color: #007a79;
          }

          &:last-child {
            background-color: #cc0000;
          }
        }
      }
    }
  }

  &.disabled .segmented-selector-section.active-segmented-selector-section {
    background-color: #cb4f00;

    .zylo-icon {
      stroke: $white;
    }

    &:first-child {
      background-color: #007a79;
    }

    &:last-child {
      background-color: #cc0000;
    }
  }

  &.can-deselect .segmented-selector-section.active-segmented-selector-section {
    cursor: pointer;
  }
}
