$formelements: '.form-element, .Select, .type-ahead, .DateInput';

.zylo-form {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 12px 0 24px 36px;

  .zylo-form-fieldset {
    position: relative;
    flex: 1 0 0;
    min-width: 300px;
    max-height: 100%;
    padding: 0 24px 12px 24px;
    border-right: $default-border;
  }

  .zylo-form-message {
    align-items: center;
    margin-top: 4px;
    font-size: $extra-small-font-size;
    font-weight: 400;

    &.default-form-error {
      margin: 4px 0;
      height: auto;
      min-height: 18px;
      width: 100%;
      white-space: pre-wrap;
      .error-message {
        display: block;
      }
    }
    .zylo-icon {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }

    .autosave-pending,
    .save-success,
    .error-message,
    .update-message {
      display: none;
    }

    .autosave-pending {
      color: #f8b231;
    }
    .error-message {
      color: #cc0000;
    }
    .save-success {
      color: #007a79;
    }

    .update-message {
      color: #007a79;

      .button {
        min-height: 20px;
        margin-left: 16px;
      }
    }
  }

  .zylo-form-field {
    position: relative;
    min-width: 260px;
    width: 100%;
    min-height: 86px;
    margin: 16px 35px 0 0;

    .zylo-form-label-wrapper {
      .zylo-form-label {
        color: #666666;
        font-size: 13px;
        transition: 2s;
      }
      .zylo-tooltip {
        margin: -4px 0 0 6px;
      }
    }

    &.validation-error {
      #{$formelements} {
        border-color: #cc0000;
      }

      .error-message {
        display: flex;
      }
      .field-instructions {
        display: none;
      }
    }

    &.disabled {
      textarea {
        resize: none;
      }
    }
  }

  &.default-form:not(.save-attempt) {
    .validation-error {
      #{$formelements} {
        border-color: $darkgray;
        transition: border-color 0.5s ease;

        &:disabled {
          background-color: $color-100;
          border-color: $color-200;
          cursor: not-allowed;
        }

        &.read-only,
        &[readonly] {
          background-color: transparent;
          border-color: transparent;
          pointer-events: none;
          resize: none;

          &:focus {
            border-color: #007a79;
          }
        }
      }

      .field-instructions {
        display: flex !important;
      }
      .error-message {
        display: none !important;
      }
    }
  }

  &.autosave-form {
    .field-updated {
      .update-message {
        display: flex;
      }
      .field-instructions {
        display: none;
      }
    }

    .save-complete {
      #{$formelements} {
        border-color: #007a79 !important;
      }

      .save-success {
        display: flex;
        align-items: center;
      }
      .autosave-pending,
      .field-instructions,
      .update-message {
        display: none;
      }
    }

    .dirty-field {
      #{$formelements} {
        border-color: #f8b231 !important;
      }

      .autosave-pending {
        display: flex;
        width: 130px;
        margin: 0;
        text-align: left;

        &:after {
          @include ellipsis-after;
        }
      }

      .field-instructions,
      .save-success,
      .update-message {
        display: none;
      }

      &.validation-error {
        #{$formelements} {
          border-color: #cc0000 !important;
        }

        .error-message {
          display: flex;
        }
        .autosave-pending,
        .update-message {
          display: none;
        }
      }
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.full-width {
    padding: 0;

    .zylo-form-field {
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }
}

// Generic Input Styles
.form-element {
  padding: 6.5px 8px;
  border: 1px solid $darkgray;
  border-radius: 4px;
  transition: border 0.5s ease;

  &:focus {
    border-color: #007a79;
  }
  &:disabled {
    background: $white;
    background-color: $color-100;
    border-color: $color-200;
    cursor: not-allowed;
  }
  &.read-only,
  &[readonly] {
    background-color: transparent;
    border-color: transparent;
    pointer-events: none;
    resize: none;

    &:focus {
      border-color: #007a79;
    }
  }
}

@media (min-width: 1010px) {
  .zylo-form .zylo-form-field {
    min-width: calc(50% - 36px);
    max-width: calc(50% - 36px);
  }
}

@media (min-width: 1420px) {
  .zylo-form .zylo-form-field {
    min-width: calc(33% - 33px);
    max-width: calc(33% - 33px);
  }
}

@media (min-width: 1860px) {
  .zylo-form .zylo-form-field {
    min-width: calc(25% - 36px);
    min-width: calc(25% - 36px);
  }
}

@media (min-width: 2300px) {
  .zylo-form .zylo-form-field {
    min-width: calc(20% - 36px);
    min-width: calc(20% - 36px);
  }
}
