.select-container {
  position: relative;
  font-size: $default-font-size;

  &.read-only {
    pointer-events: none;

    .Select {
      border: 1px solid transparent;
    }

    .Select-arrow-zone {
      display: none;
    }
  }

  .clear-select {
    display: none;
    position: absolute;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: $extra-small-font-size;
    color: $lightgray;

    &:hover {
      color: $red;

      .clear-x {
        border-color: $red;
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .clear-x {
      margin: 0 2px;
      padding: 0 4px;
      border: $default-border;
      border-radius: 50%;
    }
  }

  &:hover .clear-select {
    display: block;
  }
}

.Select {
  border: 1px solid $darkgray;
  border-radius: 4px;
  transition: border 0.5s ease;

  .Select-control {
    border: none;
    box-shadow: none !important;

    &:hover {
      box-shadow: none;
      cursor: pointer;
    }

    .Select-clear {
      display: none;
    }

    .Select-multi-value-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .Select-Placeholder {
      font-size: $default-font-size;
      cursor: default;
    }

    .Select-arrow-zone {
      padding-top: 6px;
      @include fade-in;

      .Select-arrow {
        border-width: 7px 7px 4px;
      }
    }
  }

  .Select-menu-outer {
    z-index: 3;
    margin-top: 2px;
    font-weight: 400;
    font-size: $default-font-size;
    box-shadow: 1px 2px 5px $lightgray;

    .subscription-option {
      padding-right: 8px;
      cursor: pointer;

      .app-logo-container {
        margin: 10px;
      }

      &:disabled,
      &.disabled {
        cursor: not-allowed;
        background: $lightgray !important;

        * {
          cursor: not-allowed;
        }
      }
    }

    &:hover {
      background: $lightgray;
    }
  }

  &.is-disabled {
    border-color: $color-200;

    .Select-control {
      background: none;
      cursor: not-allowed;

      .Select-arrow-zone {
        display: none;
      }
    }

    > div.Select-control {
      background-color: $color-100;
    }
  }

  &:hover:not(.is-open) .Select-arrow {
    border-top-color: $blue;
  }

  &.is-open .Select-arrow-zone {
    .Select-arrow {
      top: -4px;
      border-color: transparent transparent $blue;
      border-width: 4px 7px 7px !important;
      border-top: none;
    }

    &:hover .Select-arrow {
      top: -4px;
      border-top: none;
    }
  }

  &.is-focused {
    border-color: $blue !important;
  }
}

.Select--single {
  margin: 0;

  > .Select-control .Select-value {
    display: flex;
    align-items: center;
    padding: 0 6px;
    transition: padding 0.6s;

    .Select-value-label {
      @extend .body-black;
      position: relative;
      display: flex;
      align-items: center;
      width: 85%;
      min-height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .subscription-option {
        position: absolute;
        z-index: 2;
        width: 100%;
      }
    }
  }

  .Select-input {
    padding-left: 6px;
  }
}

.Select-placeholder {
  display: flex;
  align-items: center;
  font-size: $default-font-size;
}

.Select.select-gray {
  border: 0;

  .Select-value,
  .Select-placeholder {
    background: $lightgray;
    color: $black;
  }

  &:hover .Select-value,
  .Select-placeholder {
    background: $lightgray;
  }

  &.is-open .Select-value,
  .Select-placeholder {
    background: $lightgray;
  }

  .clear-select {
    color: $darkgray;

    .clear-x {
      border-color: $darkgray;
    }
  }
}

.Select--multi {
  margin: 0;

  .Select-value {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-left: 8px;
    padding: 6px;
    color: $white;
    background: $blue;
    font-size: $small-font-size;
    font-weight: 200;
    border: none;

    &:first-child {
      margin-left: 0;
    }

    .Select-value-label {
      padding: 2px 8px !important;
      font-size: $small-font-size;
      font-weight: 200;
    }

    .Select-value-icon {
      width: 12px;
      height: 12px;
      margin: 0 4px 0 8px;
      padding: 0;
      font-size: $small-font-size;
      text-align: center;
      border: none;
      color: white;
      background: $blue;
      font-weight: bold;
      margin-top: -5px;
    }
  }

  &.is-disabled .Select-value {
    margin: 2px 0 0 4px;
    color: $white;
    background: $blue;
    border: none;
    border-radius: 6px;
  }
}
