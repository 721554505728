.list {
  @extend .flex-container;
  @extend .vertical;

  .list-row-header {
    color: $darkgray;
  }
  .list-row,
  .list-row-header {
    @extend .flex-container;
    @extend .justify-between;
    border-bottom: $default-border;
    padding: 10px 20px;

    > *:first-child {
      text-align: left;
    }
    > *:last-child {
      text-align: right;
    }
  }
}
