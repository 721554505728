$black: #151b26;
$white: #ffffff;

//Primary Brand Colors
$navy: #153553;
$red: #cc0000;
$orange: #f48238;
$yellow: #f8b232;
$green: #007a79;
$blue: #2496d4;

//Secondary Accent Colors
$turquoise: #30e0cd;
$pink: #fc4391;
$purple: #8659a4;
$lightblue: #69b6df;

//Grays
$darkgray: #aeaeae;
$gray: #aeaeae;
$lightgray: #dedede;
$backgroundgray: #f0f2f6;
$backgroundbluegray: #f1f2f6;
$transparentgray: rgba(255, 255, 255, 0.2);
$bluegray: rgb(141, 154, 167);

//Shades
$color-100: #f0f2f6;
$color-200: #e7e7e7;
$color-300: #dedede;
$color-400: #d6d6d6;
$color-500: #c9c9c9;
$color-600: #aeaeae;
$dark: #333333;

//Hover colors
$hoverlightblue: #d2eaf6;
$blue-hover: #4fabdc;
$green-hover: #9ad871;
$navy-hover: #435d75;
$orange-hover: #f69b5f;
$pink-hover: #fc68a7;
$purple-hover: #9e7ab6;
$red-hover: #ce6b64;
$turquoise-hover: #59e6d7;
$yellow-hover: #f9c15b;

//Focus colors
$blue-pressed: #207db1;
$green-pressed: #6baa46;
$navy-pressed: #142f4a;
$orange-pressed: #c76d34;
$pink-pressed: #cd3a7c;
$purple-pressed: #6f4c8b;
$red-pressed: #9f3d39;
$turquoise-pressed: #2ab8ac;
$yellow-pressed: #ca9330;

// can be referenced in sass files
$colors: (
  black: $black,
  white: $white,
  navy: $navy,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  blue: $blue,
  turquoise: $turquoise,
  pink: $pink,
  purple: $purple,
  lightblue: $lightblue,
  darkgray: $darkgray,
  gray: $gray,
  lightgray: $lightgray,
  backgroundgray: $backgroundgray,
  backgroundbluegray: $backgroundbluegray,
  transparentgray: $transparentgray,
);

$button-colors: (
  blue: (
    default: $blue,
    hover: $blue-hover,
    pressed: $blue-pressed,
  ),
  gray: (
    default: $color-600,
    hover: $color-300,
    pressed: $color-600,
  ),
  green: (
    default: $green,
    hover: $green-hover,
    pressed: $green-pressed,
  ),
  navy: (
    default: $navy,
    hover: $navy-hover,
    pressed: $navy-pressed,
  ),
  orange: (
    default: $orange,
    hover: $orange-hover,
    pressed: $orange-pressed,
  ),
  pink: (
    default: $pink,
    hover: $pink-hover,
    pressed: $pink-pressed,
  ),
  purple: (
    default: $purple,
    hover: $purple-hover,
    pressed: $purple-pressed,
  ),
  red: (
    default: $red,
    hover: $red-hover,
    pressed: $red-pressed,
  ),
  turquoise: (
    default: $turquoise,
    hover: $turquoise-hover,
    pressed: $turquoise-pressed,
  ),
  yellow: (
    default: $yellow,
    hover: $yellow-hover,
    pressed: $yellow-pressed,
  ),
);

$default-transition: 0.2s;
$details-header-height: 58px;
$default-border: 1px solid $lightgray;

$extra-large-font-size: 3rem; // 48px
$large-font-size: 2rem; // 32px
$extra-medium-font-size: 1.5rem; // 24px
$medium-font-size: 1.25rem; // 20px
$default-font-size: 1rem; // 16px
$small-font-size: 0.875rem; // 14px
$extra-small-font-size: 0.75rem; // 12px

//  Media Queries
@mixin bp($point) {
  @if $point == 1400up {
    @media (min-width: 1400px) {
      @content;
    }
  }

  @if $point == 1200up {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @if $point == 960up {
    @media (min-width: 960px) {
      @content;
    }
  } @else if $point == 720up {
    @media (min-width: 720px) {
      @content;
    }
  } @else if $point == 720down {
    @media (max-width: 719px) {
      @content;
    }
  } @else if $point == 480up {
    @media (min-width: 480px) {
      @content;
    }
  } @else if $point == 320up {
    @media (min-width: 320px) {
      @content;
    }
  }
}

@mixin vcenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absvCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin invisible {
  opacity: 0;
  visibility: hidden;
}

@mixin inView {
  opacity: 1;
  visibility: visible;
}

@mixin hidden {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  transition: visibility 0s linear 500ms, opacity 500ms;
}

@mixin fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 500ms;
}

@mixin fade-in {
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100;
    }
  }

  animation: fade-in 0.5s ease-in;
}

@mixin halfSecondTransition {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* non-prefixed version, currently not widely supported*/
}

@mixin spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin ellipsis-after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1000ms infinite;
  animation: ellipsis steps(4, end) 1000ms infinite;
  content: '\2026';
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@mixin edit-mode-indicator($color) {
  $lightcolor: lighten($color, 10%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background: $color;
    background-image: linear-gradient(
      45deg,
      $lightcolor 25%,
      transparent 25.5%,
      transparent 50%,
      $lightcolor 50.5%,
      $lightcolor 75%,
      transparent 75.5%,
      transparent
    );
    background-size: 30px 30px;
    transition: height 500ms;
  }

  &.edit-mode::before {
    height: 12px;
  }
}

// Functions
@function set-text-color($color) {
  @if (lightness($color) > 80) {
    @return $dark; // Lighter background, return dark color
  } @else {
    @return $white; // Darker background, return light color
  }
}
