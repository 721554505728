.metadata {
  height: 100%;

  .metadata-header {
    font-weight: 400;
    & > span {
      line-height: calc(1.6rem + 20px);
    }

    .metadata-title {
      font-weight: normal;
    }
  }

  .metadata-body {
    overflow-y: auto;
    flex: 1 0 0;
  }

  .metadata-field {
    @extend .vertical;
    .metadata-label {
      color: $darkgray;
      font-size: $small-font-size;
      font-weight: 400;
      padding: 5px 0px;
    }

    .metadata-value {
      font-weight: 400;
      padding: 5px 0px;
    }
    .metadata-label,
    .metadata-value {
      width: 100%;
      text-align: left;
    }
  }
}
