.plan-row {
  flex-flow: row nowrap;
  min-height: 60px;

  & > div {
    height: auto;
    margin: 0 16px;
  }

  .heading,
  .subheading {
    display: block;
    font-weight: 200;
  }

  .heading {
    font-size: $medium-font-size;
  }

  .plan-type {
    flex-grow: 2;
    flex-shrink: 2;
  }

  .plan-cell {
    text-align: center;
    padding: 0 5px;

    .heading {
      color: $blue;
    }

    // Temporarily disabled until backend can calculate this
    &.plan-purchased {
      display: none;
    }

    &.plan-inactive .heading {
      color: $red;
    }
  }

  .plan-users {
    flex-grow: 0;
    flex-shrink: 0;

    a {
      cursor: pointer;
      color: $orange;
      vertical-align: bottom;

      &:hover .plan-users-icon path {
        animation: 0.5s ease-in-out 0s infinite alternate both point_right_bounce;
      }
    }
  }
}

@keyframes point_right_bounce {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(50%);
  }
}
