.filters-display {
  min-height: auto;
  padding: 0 4px 6px 4px;
  transition: min-height 1s, padding 1s;

  .filters-display-transition-container {
    max-height: 200px;
    transition: max-height 1s;
    overflow: auto;
  }

  .filters-display-group {
    margin: 6px 12px 0 0;
    font-size: 13px;

    .filters-display-separator {
      color: #333;
      margin-right: 12px;
    }

    .filters-display-type {
      color: $black;
      white-space: nowrap;
    }

    .filters-display-values-container {
      max-width: 60vw;

      .filters-display-value {
        align-items: center;
        margin: 5px;
        max-width: 300px;

        span :not(.MuiChip-icon) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        &.is-invalid {
          background: $red;
        }

        .zylo-icon {
          path {
            pointer-events: none;
          }
        }
      }
    }

    &.invalid-filter {
      .filters-display-type {
        color: $red;

        &:before {
          content: '* ';
          color: $red;
        }
      }
    }
  }

  &.no-filters {
    min-height: 0;
    padding: 0;

    .filters-display-transition-container {
      max-height: 0;
    }
  }
}
